export const barcelona = {
  name: "barcelona",
  // COMMON UTILS  ----
  parseFloat: function (val: string | number, min = 0, max = 0) {
    let ret: number | null = parseFloat(String(val));
    ret = Number.isNaN(ret) ? 0 : ret;
    if (min && ret && ret < min) {
      return 0;
    }
    if (max && ret && ret > max) {
      return 0;
    }
    return Number.isNaN(ret) ? 0 : ret;
  },
  daysToWeeks: (days?: number | null) => {
    if (Number.isNaN(days) || !days) return null;
    return Number(barcelona.parseFloat(days / 7).toFixed(2));
  },

  // WEIGHT MATHS ------
  centileFromFW: function (
    fw?: number | null,
    ga?: number | null,
    sexo?: number | null
  ) {
    // sex 1 = mujer | 0 macho
    const inputPeso = fw;
    const MIN_WEIGHT = 200;
    const MAX_WEIGHT = 6000;
    const MIN_GA = 22 * 7;
    const MAX_GA = 44 * 7;

    if (!sexo) sexo = 1;

    if (!fw || fw > MAX_WEIGHT || fw <= MIN_WEIGHT) return null;
    if (!ga || ga > MAX_GA || ga < MIN_GA) return null;

    const pp_txt = 3431.64 + Number(sexo) * -103.056;

    const floInterest = ga / 7;

    const pp50c_txt =
      2.991 -
      floInterest * 0.3185 +
      floInterest * floInterest * 0.01094 -
      floInterest * floInterest * floInterest * 0.0001055;
    const pp10c_txt = 0.86 * pp50c_txt;

    const p50c_txt = pp_txt * pp50c_txt;
    const p10c_txt = pp_txt * pp10c_txt;

    const s_txt = (p50c_txt - p10c_txt) / 1.28;

    const error_txt = Number(inputPeso) - (69.906 - 0.023 * Number(inputPeso));

    const z_txt = (error_txt - p50c_txt) / s_txt;

    let flag_txt, z2, centile;
    if (z_txt < 0) {
      flag_txt = 1;
      z2 = -1 * z_txt;
    } else {
      flag_txt = 0;
      z2 = z_txt;
    }

    const bb = z2 / 1000;
    let nn = 0;
    let sa = 0;

    while (nn < 1000) {
      nn = nn + 1;
      const zn = nn * bb - bb / 2;
      const y = Math.exp((-1 * zn * zn) / 2) / 2.506628;
      const a1 = bb * y;
      sa = sa + a1;
    }
    const p = sa + 0.5;

    if (flag_txt == 1) {
      centile = Math.round(100 * (1 - p));
    } else {
      centile = Math.round(100 * p);
    }
    return centile;
  },

  // Obtengo Peso desde percentil + ga
  // ga en gramos
  // centile en días.
  calcEFW: function (ga?: number | null, centile?: number | null) {
    if (typeof ga !== "number" || typeof centile !== "number") return null;
    let low = 250;
    let high = 5000;
    let weight = null;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const weightCentile = barcelona.centileFromFW(mid, ga);

      if (weightCentile === null) {
        low += 1;
      } else if (weightCentile === centile) {
        weight = mid;
        break;
      } else if (weightCentile < centile) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    return weight;
  },

  // DOPPLER MATHS ----
  /* Estas funciones reciben todos los inputs, comprueban valores 'Out of range'
   y muestran el resultado.
   Se llaman con un onclick, desde el propio botón Calculate */

  // Percentil de IP arteria uterina // doppler_arterias_uterinas
  uterineArteryPICentile: function (utpi?: number | null, ga?: number | null) {
    let percentil = "";
    const MAX_GA = 44 * 7;
    const MIN_GA = 11 * 7;

    if (!utpi || utpi <= 0.1 || utpi > 10) {
      return null;
    } else if (!ga || ga > MAX_GA || ga < MIN_GA) {
      return null;
    }

    const Utam = utpi;
    const Utamlog = Math.log(Utam);
    const Utazs =
      (Utamlog - (1.39 - 0.012 * ga + 1.98e-5 * (ga * ga))) /
      (0.272 - 0.000259 * ga);

    if (Utazs >= 2.6) {
      percentil = "100";
    } else if (Utazs < 2.6 && Utazs >= 2.18) {
      percentil = "99";
    } else if (Utazs < 2.18 && Utazs >= 1.97) {
      percentil = "98";
    } else if (Utazs < 1.97 && Utazs >= 1.81) {
      percentil = "97";
    } else if (Utazs < 1.81 && Utazs > 1.645) {
      percentil = "96";
    } else if (Utazs <= 1.645 && Utazs >= 1.6) {
      percentil = "95";
    } else if (Utazs < 1.6 && Utazs >= 1.52) {
      percentil = "94";
    } else if (Utazs < 1.52 && Utazs >= 1.45) {
      percentil = "93";
    } else if (Utazs < 1.45 && Utazs >= 1.38) {
      percentil = "92";
    } else if (Utazs < 1.38 && Utazs >= 1.31) {
      percentil = "91";
    } else if (Utazs < 1.31 && Utazs >= 1.26) {
      percentil = "90";
    } else if (Utazs < 1.26 && Utazs >= 1.2) {
      percentil = "89";
    } else if (Utazs < 1.2 && Utazs >= 1.17) {
      percentil = "88";
    } else if (Utazs < 1.17 && Utazs >= 1.1) {
      percentil = "87";
    } else if (Utazs < 1.1 && Utazs >= 1.07) {
      percentil = "86";
    } else if (Utazs < 1.07 && Utazs >= 1.02) {
      percentil = "85";
    } else if (Utazs < 1.02 && Utazs >= 0.98) {
      percentil = "84";
    } else if (Utazs < 0.98 && Utazs >= 0.94) {
      percentil = "83";
    } else if (Utazs < 0.94 && Utazs >= 0.9) {
      percentil = "82";
    } else if (Utazs < 0.9 && Utazs >= 0.86) {
      percentil = "81";
    } else if (Utazs < 0.86 && Utazs >= 0.84) {
      percentil = "80";
    } else if (Utazs < 0.84 && Utazs >= 0.79) {
      percentil = "79";
    } else if (Utazs < 0.79 && Utazs >= 0.76) {
      percentil = "78";
    } else if (Utazs < 0.76 && Utazs >= 0.72) {
      percentil = "77";
    } else if (Utazs < 0.72 && Utazs >= 0.69) {
      percentil = "76";
    } else if (Utazs < 0.69 && Utazs >= 0.67) {
      percentil = "75";
    } else if (Utazs < 0.67 && Utazs >= 0.63) {
      percentil = "74";
    } else if (Utazs < 0.63 && Utazs >= 0.61) {
      percentil = "73";
    } else if (Utazs < 0.61 && Utazs >= 0.57) {
      percentil = "72";
    } else if (Utazs < 0.57 && Utazs >= 0.54) {
      percentil = "71";
    } else if (Utazs < 0.54 && Utazs >= 0.51) {
      percentil = "70";
    } else if (Utazs < 0.51 && Utazs >= 0.48) {
      percentil = "69";
    } else if (Utazs < 0.48 && Utazs >= 0.46) {
      percentil = "68";
    } else if (Utazs < 0.46 && Utazs >= 0.43) {
      percentil = "67";
    } else if (Utazs < 0.43 && Utazs >= 0.4) {
      percentil = "66";
    } else if (Utazs < 0.4 && Utazs >= 0.37) {
      percentil = "65";
    } else if (Utazs < 0.37 && Utazs >= 0.34) {
      percentil = "64";
    } else if (Utazs < 0.34 && Utazs >= 0.32) {
      percentil = "63";
    } else if (Utazs < 0.32 && Utazs >= 0.3) {
      percentil = "62";
    } else if (Utazs < 0.3 && Utazs >= 0.27) {
      percentil = "61";
    } else if (Utazs < 0.27 && Utazs >= 0.24) {
      percentil = "60";
    } else if (Utazs < 0.24 && Utazs >= 0.22) {
      percentil = "59";
    } else if (Utazs < 0.22 && Utazs >= 0.19) {
      percentil = "58";
    } else if (Utazs < 0.19 && Utazs >= 0.17) {
      percentil = "57";
    } else if (Utazs < 0.17 && Utazs >= 0.14) {
      percentil = "56";
    } else if (Utazs < 0.14 && Utazs >= 0.12) {
      percentil = "55";
    } else if (Utazs < 0.12 && Utazs >= 0.09) {
      percentil = "54";
    } else if (Utazs < 0.09 && Utazs >= 0.07) {
      percentil = "53";
    } else if (Utazs < 0.07 && Utazs >= 0.05) {
      percentil = "52";
    } else if (Utazs < 0.05 && Utazs >= 0.03) {
      percentil = "51";
    } else if (Utazs < 0.03 && Utazs >= -0.01) {
      percentil = "50";
    } else if (Utazs < -0.01 && Utazs >= -0.035) {
      percentil = "49";
    } else if (Utazs < -0.035 && Utazs >= -0.06) {
      percentil = "48";
    } else if (Utazs < -0.06 && Utazs >= -0.08) {
      percentil = "47";
    } else if (Utazs < -0.08 && Utazs >= -0.11) {
      percentil = "46";
    } else if (Utazs < -0.11 && Utazs >= -0.13) {
      percentil = "45";
    } else if (Utazs < -0.13 && Utazs >= -0.16) {
      percentil = "44";
    } else if (Utazs < -0.16 && Utazs >= -0.185) {
      percentil = "43";
    } else if (Utazs < -0.185 && Utazs >= -0.21) {
      percentil = "42";
    } else if (Utazs < -0.21 && Utazs >= -0.24) {
      percentil = "41";
    } else if (Utazs < -0.24 && Utazs >= -0.26) {
      percentil = "40";
    } else if (Utazs < -0.26 && Utazs >= -0.28) {
      percentil = "39";
    } else if (Utazs < -0.28 && Utazs >= -0.31) {
      percentil = "38";
    } else if (Utazs < -0.31 && Utazs >= -0.34) {
      percentil = "37";
    } else if (Utazs < -0.34 && Utazs >= -0.36) {
      percentil = "36";
    } else if (Utazs < -0.36 && Utazs >= -0.4) {
      percentil = "35";
    } else if (Utazs < -0.4 && Utazs >= -0.43) {
      percentil = "34";
    } else if (Utazs < -0.43 && Utazs >= -0.45) {
      percentil = "33";
    } else if (Utazs < -0.45 && Utazs >= -0.48) {
      percentil = "32";
    } else if (Utazs < -0.48 && Utazs >= -0.5) {
      percentil = "31";
    } else if (Utazs < -0.5 && Utazs >= -0.54) {
      percentil = "30";
    } else if (Utazs < -0.54 && Utazs >= -0.565) {
      percentil = "29";
    } else if (Utazs < -0.565 && Utazs >= -0.585) {
      percentil = "28";
    } else if (Utazs < -0.585 && Utazs >= -0.62) {
      percentil = "27";
    } else if (Utazs < -0.62 && Utazs >= -0.65) {
      percentil = "26";
    } else if (Utazs < -0.65 && Utazs >= -0.69) {
      percentil = "25";
    } else if (Utazs < -0.69 && Utazs >= -0.73) {
      percentil = "24";
    } else if (Utazs < -0.73 && Utazs >= -0.75) {
      percentil = "23";
    } else if (Utazs < -0.75 && Utazs >= -0.79) {
      percentil = "22";
    } else if (Utazs < -0.79 && Utazs >= -0.83) {
      percentil = "21";
    } else if (Utazs < -0.83 && Utazs >= -0.85) {
      percentil = "20";
    } else if (Utazs < -0.85 && Utazs >= -0.89) {
      percentil = "19";
    } else if (Utazs < -0.89 && Utazs >= -0.93) {
      percentil = "18";
    } else if (Utazs < -0.93 && Utazs >= -0.97) {
      percentil = "17";
    } else if (Utazs < -0.97 && Utazs >= -1.01) {
      percentil = "16";
    } else if (Utazs < -1.01 && Utazs >= -1.04) {
      percentil = "15";
    } else if (Utazs < -1.04 && Utazs >= -1.1) {
      percentil = "14";
    } else if (Utazs < -1.1 && Utazs >= -1.14) {
      percentil = "13";
    } else if (Utazs < -1.14 && Utazs >= -1.2) {
      percentil = "12";
    } else if (Utazs < -1.2 && Utazs >= -1.25) {
      percentil = "11";
    } else if (Utazs < -1.25 && Utazs >= -1.31) {
      percentil = "10";
    } else if (Utazs < -1.31 && Utazs >= -1.37) {
      percentil = "9";
    } else if (Utazs < -1.37 && Utazs >= -1.43) {
      percentil = "8";
    } else if (Utazs < -1.43 && Utazs >= -1.51) {
      percentil = "7";
    } else if (Utazs < -1.51 && Utazs >= -1.6) {
      percentil = "6";
    } else if (Utazs < -1.6 && Utazs >= -1.645) {
      percentil = "5";
    } else if (Utazs < -1.645 && Utazs >= -1.81) {
      percentil = "4";
    } else if (Utazs < -1.81 && Utazs >= -1.97) {
      percentil = "3";
    } else if (Utazs < -1.97 && Utazs >= -2.18) {
      percentil = "2";
    } else if (Utazs < -2.18 && Utazs >= -2.6) {
      percentil = "1";
    } else if (Utazs < -2.6) {
      percentil = "0";
    }

    return Number(percentil);
  },

  MIN_GA_TO_UMBILICAL_ARTERY_CENTILE: 139,

  // Calc centile from UA (Umbilical Artery PI) // doppler_arteria_umbilical
  umbilicalArteryPICentile: function (ua?: number | null, ga?: number | null) {
    const decimalWeeksGa = this.daysToWeeks(ga);
    let percentil = "";
    const MAX_GA = 44 * 7;

    if (!ua || ua <= 0.1 || ua > 10) {
      return null;
    }
    if (
      !ga ||
      !decimalWeeksGa ||
      ga > MAX_GA ||
      ga < this.MIN_GA_TO_UMBILICAL_ARTERY_CENTILE
    ) {
      return null;
    }

    const UAzs =
      (ua -
        (3.55219 -
          0.13558 * decimalWeeksGa +
          0.00174 * (decimalWeeksGa * decimalWeeksGa))) /
      0.299;

    if (UAzs >= 2.6) {
      percentil = "100";
    } else if (UAzs < 2.6 && UAzs >= 2.18) {
      percentil = "99";
    } else if (UAzs < 2.18 && UAzs >= 1.97) {
      percentil = "98";
    } else if (UAzs < 1.97 && UAzs >= 1.81) {
      percentil = "97";
    } else if (UAzs < 1.81 && UAzs > 1.645) {
      percentil = "96";
    } else if (UAzs <= 1.645 && UAzs >= 1.6) {
      percentil = "95";
    } else if (UAzs < 1.6 && UAzs >= 1.52) {
      percentil = "94";
    } else if (UAzs < 1.52 && UAzs >= 1.45) {
      percentil = "93";
    } else if (UAzs < 1.45 && UAzs >= 1.38) {
      percentil = "92";
    } else if (UAzs < 1.38 && UAzs >= 1.31) {
      percentil = "91";
    } else if (UAzs < 1.31 && UAzs >= 1.26) {
      percentil = "90";
    } else if (UAzs < 1.26 && UAzs >= 1.2) {
      percentil = "89";
    } else if (UAzs < 1.2 && UAzs >= 1.17) {
      percentil = "88";
    } else if (UAzs < 1.17 && UAzs >= 1.1) {
      percentil = "87";
    } else if (UAzs < 1.1 && UAzs >= 1.07) {
      percentil = "86";
    } else if (UAzs < 1.07 && UAzs >= 1.02) {
      percentil = "85";
    } else if (UAzs < 1.02 && UAzs >= 0.98) {
      percentil = "84";
    } else if (UAzs < 0.98 && UAzs >= 0.94) {
      percentil = "83";
    } else if (UAzs < 0.94 && UAzs >= 0.9) {
      percentil = "82";
    } else if (UAzs < 0.9 && UAzs >= 0.86) {
      percentil = "81";
    } else if (UAzs < 0.86 && UAzs >= 0.84) {
      percentil = "80";
    } else if (UAzs < 0.84 && UAzs >= 0.79) {
      percentil = "79";
    } else if (UAzs < 0.79 && UAzs >= 0.76) {
      percentil = "78";
    } else if (UAzs < 0.76 && UAzs >= 0.72) {
      percentil = "77";
    } else if (UAzs < 0.72 && UAzs >= 0.69) {
      percentil = "76";
    } else if (UAzs < 0.69 && UAzs >= 0.67) {
      percentil = "75";
    } else if (UAzs < 0.67 && UAzs >= 0.63) {
      percentil = "74";
    } else if (UAzs < 0.63 && UAzs >= 0.61) {
      percentil = "73";
    } else if (UAzs < 0.61 && UAzs >= 0.57) {
      percentil = "72";
    } else if (UAzs < 0.57 && UAzs >= 0.54) {
      percentil = "71";
    } else if (UAzs < 0.54 && UAzs >= 0.51) {
      percentil = "70";
    } else if (UAzs < 0.51 && UAzs >= 0.48) {
      percentil = "69";
    } else if (UAzs < 0.48 && UAzs >= 0.46) {
      percentil = "68";
    } else if (UAzs < 0.46 && UAzs >= 0.43) {
      percentil = "67";
    } else if (UAzs < 0.43 && UAzs >= 0.4) {
      percentil = "66";
    } else if (UAzs < 0.4 && UAzs >= 0.37) {
      percentil = "65";
    } else if (UAzs < 0.37 && UAzs >= 0.34) {
      percentil = "64";
    } else if (UAzs < 0.34 && UAzs >= 0.32) {
      percentil = "63";
    } else if (UAzs < 0.32 && UAzs >= 0.3) {
      percentil = "62";
    } else if (UAzs < 0.3 && UAzs >= 0.27) {
      percentil = "61";
    } else if (UAzs < 0.27 && UAzs >= 0.24) {
      percentil = "60";
    } else if (UAzs < 0.24 && UAzs >= 0.22) {
      percentil = "59";
    } else if (UAzs < 0.22 && UAzs >= 0.19) {
      percentil = "58";
    } else if (UAzs < 0.19 && UAzs >= 0.17) {
      percentil = "57";
    } else if (UAzs < 0.17 && UAzs >= 0.14) {
      percentil = "56";
    } else if (UAzs < 0.14 && UAzs >= 0.12) {
      percentil = "55";
    } else if (UAzs < 0.12 && UAzs >= 0.09) {
      percentil = "54";
    } else if (UAzs < 0.09 && UAzs >= 0.07) {
      percentil = "53";
    } else if (UAzs < 0.07 && UAzs >= 0.05) {
      percentil = "52";
    } else if (UAzs < 0.05 && UAzs >= 0.03) {
      percentil = "51";
    } else if (UAzs < 0.03 && UAzs >= -0.01) {
      percentil = "50";
    } else if (UAzs < -0.01 && UAzs >= -0.035) {
      percentil = "49";
    } else if (UAzs < -0.035 && UAzs >= -0.06) {
      percentil = "48";
    } else if (UAzs < -0.06 && UAzs >= -0.08) {
      percentil = "47";
    } else if (UAzs < -0.08 && UAzs >= -0.11) {
      percentil = "46";
    } else if (UAzs < -0.11 && UAzs >= -0.13) {
      percentil = "45";
    } else if (UAzs < -0.13 && UAzs >= -0.16) {
      percentil = "44";
    } else if (UAzs < -0.16 && UAzs >= -0.185) {
      percentil = "43";
    } else if (UAzs < -0.185 && UAzs >= -0.21) {
      percentil = "42";
    } else if (UAzs < -0.21 && UAzs >= -0.24) {
      percentil = "41";
    } else if (UAzs < -0.24 && UAzs >= -0.26) {
      percentil = "40";
    } else if (UAzs < -0.26 && UAzs >= -0.28) {
      percentil = "39";
    } else if (UAzs < -0.28 && UAzs >= -0.31) {
      percentil = "38";
    } else if (UAzs < -0.31 && UAzs >= -0.34) {
      percentil = "37";
    } else if (UAzs < -0.34 && UAzs >= -0.36) {
      percentil = "36";
    } else if (UAzs < -0.36 && UAzs >= -0.4) {
      percentil = "35";
    } else if (UAzs < -0.4 && UAzs >= -0.43) {
      percentil = "34";
    } else if (UAzs < -0.43 && UAzs >= -0.45) {
      percentil = "33";
    } else if (UAzs < -0.45 && UAzs >= -0.48) {
      percentil = "32";
    } else if (UAzs < -0.48 && UAzs >= -0.5) {
      percentil = "31";
    } else if (UAzs < -0.5 && UAzs >= -0.54) {
      percentil = "30";
    } else if (UAzs < -0.54 && UAzs >= -0.565) {
      percentil = "29";
    } else if (UAzs < -0.565 && UAzs >= -0.585) {
      percentil = "28";
    } else if (UAzs < -0.585 && UAzs >= -0.62) {
      percentil = "27";
    } else if (UAzs < -0.62 && UAzs >= -0.65) {
      percentil = "26";
    } else if (UAzs < -0.65 && UAzs >= -0.69) {
      percentil = "25";
    } else if (UAzs < -0.69 && UAzs >= -0.73) {
      percentil = "24";
    } else if (UAzs < -0.73 && UAzs >= -0.75) {
      percentil = "23";
    } else if (UAzs < -0.75 && UAzs >= -0.79) {
      percentil = "22";
    } else if (UAzs < -0.79 && UAzs >= -0.83) {
      percentil = "21";
    } else if (UAzs < -0.83 && UAzs >= -0.85) {
      percentil = "20";
    } else if (UAzs < -0.85 && UAzs >= -0.89) {
      percentil = "19";
    } else if (UAzs < -0.89 && UAzs >= -0.93) {
      percentil = "18";
    } else if (UAzs < -0.93 && UAzs >= -0.97) {
      percentil = "17";
    } else if (UAzs < -0.97 && UAzs >= -1.01) {
      percentil = "16";
    } else if (UAzs < -1.01 && UAzs >= -1.04) {
      percentil = "15";
    } else if (UAzs < -1.04 && UAzs >= -1.1) {
      percentil = "14";
    } else if (UAzs < -1.1 && UAzs >= -1.14) {
      percentil = "13";
    } else if (UAzs < -1.14 && UAzs >= -1.2) {
      percentil = "12";
    } else if (UAzs < -1.2 && UAzs >= -1.25) {
      percentil = "11";
    } else if (UAzs < -1.25 && UAzs >= -1.31) {
      percentil = "10";
    } else if (UAzs < -1.31 && UAzs >= -1.37) {
      percentil = "9";
    } else if (UAzs < -1.37 && UAzs >= -1.43) {
      percentil = "8";
    } else if (UAzs < -1.43 && UAzs >= -1.51) {
      percentil = "7";
    } else if (UAzs < -1.51 && UAzs >= -1.6) {
      percentil = "6";
    } else if (UAzs < -1.6 && UAzs >= -1.645) {
      percentil = "5";
    } else if (UAzs < -1.645 && UAzs >= -1.81) {
      percentil = "4";
    } else if (UAzs < -1.81 && UAzs >= -1.97) {
      percentil = "3";
    } else if (UAzs < -1.97 && UAzs >= -2.18) {
      percentil = "2";
    } else if (UAzs < -2.18 && UAzs >= -2.6) {
      percentil = "1";
    } else if (UAzs < -2.6) {
      percentil = "0";
    }

    return Number(percentil);
  },

  // Calc centile from MCA (Middle cerebral PI)
  // doppler_arteria_cerebral_media
  middleCerebralPICentile: function (mca?: number | null, ga?: number | null) {
    const decimalWeeksGa = this.daysToWeeks(ga);
    let percentil = "";
    const MAX_GA = 44 * 7;
    const MIN_GA = 20 * 7;

    if (!mca || mca <= 0.1 || mca > 10) {
      return null;
    }
    if (!ga || !decimalWeeksGa || ga > MAX_GA || ga < MIN_GA) {
      return null;
    }

    const ACMzs =
      (mca -
        (-2.7317 +
          0.3335 * decimalWeeksGa -
          0.0058 * (decimalWeeksGa * decimalWeeksGa))) /
      (-0.88005 +
        0.08182 * decimalWeeksGa -
        0.00133 * (decimalWeeksGa * decimalWeeksGa));

    if (ACMzs >= 2.6) {
      percentil = "100";
    } else if (ACMzs < 2.6 && ACMzs >= 2.18) {
      percentil = "99";
    } else if (ACMzs < 2.18 && ACMzs >= 1.97) {
      percentil = "98";
    } else if (ACMzs < 1.97 && ACMzs >= 1.81) {
      percentil = "97";
    } else if (ACMzs < 1.81 && ACMzs > 1.645) {
      percentil = "96";
    } else if (ACMzs <= 1.645 && ACMzs >= 1.6) {
      percentil = "95";
    } else if (ACMzs < 1.6 && ACMzs >= 1.52) {
      percentil = "94";
    } else if (ACMzs < 1.52 && ACMzs >= 1.45) {
      percentil = "93";
    } else if (ACMzs < 1.45 && ACMzs >= 1.38) {
      percentil = "92";
    } else if (ACMzs < 1.38 && ACMzs >= 1.31) {
      percentil = "91";
    } else if (ACMzs < 1.31 && ACMzs >= 1.26) {
      percentil = "90";
    } else if (ACMzs < 1.26 && ACMzs >= 1.2) {
      percentil = "89";
    } else if (ACMzs < 1.2 && ACMzs >= 1.17) {
      percentil = "88";
    } else if (ACMzs < 1.17 && ACMzs >= 1.1) {
      percentil = "87";
    } else if (ACMzs < 1.1 && ACMzs >= 1.07) {
      percentil = "86";
    } else if (ACMzs < 1.07 && ACMzs >= 1.02) {
      percentil = "85";
    } else if (ACMzs < 1.02 && ACMzs >= 0.98) {
      percentil = "84";
    } else if (ACMzs < 0.98 && ACMzs >= 0.94) {
      percentil = "83";
    } else if (ACMzs < 0.94 && ACMzs >= 0.9) {
      percentil = "82";
    } else if (ACMzs < 0.9 && ACMzs >= 0.86) {
      percentil = "81";
    } else if (ACMzs < 0.86 && ACMzs >= 0.84) {
      percentil = "80";
    } else if (ACMzs < 0.84 && ACMzs >= 0.79) {
      percentil = "79";
    } else if (ACMzs < 0.79 && ACMzs >= 0.76) {
      percentil = "78";
    } else if (ACMzs < 0.76 && ACMzs >= 0.72) {
      percentil = "77";
    } else if (ACMzs < 0.72 && ACMzs >= 0.69) {
      percentil = "76";
    } else if (ACMzs < 0.69 && ACMzs >= 0.67) {
      percentil = "75";
    } else if (ACMzs < 0.67 && ACMzs >= 0.63) {
      percentil = "74";
    } else if (ACMzs < 0.63 && ACMzs >= 0.61) {
      percentil = "73";
    } else if (ACMzs < 0.61 && ACMzs >= 0.57) {
      percentil = "72";
    } else if (ACMzs < 0.57 && ACMzs >= 0.54) {
      percentil = "71";
    } else if (ACMzs < 0.54 && ACMzs >= 0.51) {
      percentil = "70";
    } else if (ACMzs < 0.51 && ACMzs >= 0.48) {
      percentil = "69";
    } else if (ACMzs < 0.48 && ACMzs >= 0.46) {
      percentil = "68";
    } else if (ACMzs < 0.46 && ACMzs >= 0.43) {
      percentil = "67";
    } else if (ACMzs < 0.43 && ACMzs >= 0.4) {
      percentil = "66";
    } else if (ACMzs < 0.4 && ACMzs >= 0.37) {
      percentil = "65";
    } else if (ACMzs < 0.37 && ACMzs >= 0.34) {
      percentil = "64";
    } else if (ACMzs < 0.34 && ACMzs >= 0.32) {
      percentil = "63";
    } else if (ACMzs < 0.32 && ACMzs >= 0.3) {
      percentil = "62";
    } else if (ACMzs < 0.3 && ACMzs >= 0.27) {
      percentil = "61";
    } else if (ACMzs < 0.27 && ACMzs >= 0.24) {
      percentil = "60";
    } else if (ACMzs < 0.24 && ACMzs >= 0.22) {
      percentil = "59";
    } else if (ACMzs < 0.22 && ACMzs >= 0.19) {
      percentil = "58";
    } else if (ACMzs < 0.19 && ACMzs >= 0.17) {
      percentil = "57";
    } else if (ACMzs < 0.17 && ACMzs >= 0.14) {
      percentil = "56";
    } else if (ACMzs < 0.14 && ACMzs >= 0.12) {
      percentil = "55";
    } else if (ACMzs < 0.12 && ACMzs >= 0.09) {
      percentil = "54";
    } else if (ACMzs < 0.09 && ACMzs >= 0.07) {
      percentil = "53";
    } else if (ACMzs < 0.07 && ACMzs >= 0.05) {
      percentil = "52";
    } else if (ACMzs < 0.05 && ACMzs >= 0.03) {
      percentil = "51";
    } else if (ACMzs < 0.03 && ACMzs >= -0.01) {
      percentil = "50";
    } else if (ACMzs < -0.01 && ACMzs >= -0.035) {
      percentil = "49";
    } else if (ACMzs < -0.035 && ACMzs >= -0.06) {
      percentil = "48";
    } else if (ACMzs < -0.06 && ACMzs >= -0.08) {
      percentil = "47";
    } else if (ACMzs < -0.08 && ACMzs >= -0.11) {
      percentil = "46";
    } else if (ACMzs < -0.11 && ACMzs >= -0.13) {
      percentil = "45";
    } else if (ACMzs < -0.13 && ACMzs >= -0.16) {
      percentil = "44";
    } else if (ACMzs < -0.16 && ACMzs >= -0.185) {
      percentil = "43";
    } else if (ACMzs < -0.185 && ACMzs >= -0.21) {
      percentil = "42";
    } else if (ACMzs < -0.21 && ACMzs >= -0.24) {
      percentil = "41";
    } else if (ACMzs < -0.24 && ACMzs >= -0.26) {
      percentil = "40";
    } else if (ACMzs < -0.26 && ACMzs >= -0.28) {
      percentil = "39";
    } else if (ACMzs < -0.28 && ACMzs >= -0.31) {
      percentil = "38";
    } else if (ACMzs < -0.31 && ACMzs >= -0.34) {
      percentil = "37";
    } else if (ACMzs < -0.34 && ACMzs >= -0.36) {
      percentil = "36";
    } else if (ACMzs < -0.36 && ACMzs >= -0.4) {
      percentil = "35";
    } else if (ACMzs < -0.4 && ACMzs >= -0.43) {
      percentil = "34";
    } else if (ACMzs < -0.43 && ACMzs >= -0.45) {
      percentil = "33";
    } else if (ACMzs < -0.45 && ACMzs >= -0.48) {
      percentil = "32";
    } else if (ACMzs < -0.48 && ACMzs >= -0.5) {
      percentil = "31";
    } else if (ACMzs < -0.5 && ACMzs >= -0.54) {
      percentil = "30";
    } else if (ACMzs < -0.54 && ACMzs >= -0.565) {
      percentil = "29";
    } else if (ACMzs < -0.565 && ACMzs >= -0.585) {
      percentil = "28";
    } else if (ACMzs < -0.585 && ACMzs >= -0.62) {
      percentil = "27";
    } else if (ACMzs < -0.62 && ACMzs >= -0.65) {
      percentil = "26";
    } else if (ACMzs < -0.65 && ACMzs >= -0.69) {
      percentil = "25";
    } else if (ACMzs < -0.69 && ACMzs >= -0.73) {
      percentil = "24";
    } else if (ACMzs < -0.73 && ACMzs >= -0.75) {
      percentil = "23";
    } else if (ACMzs < -0.75 && ACMzs >= -0.79) {
      percentil = "22";
    } else if (ACMzs < -0.79 && ACMzs >= -0.83) {
      percentil = "21";
    } else if (ACMzs < -0.83 && ACMzs >= -0.85) {
      percentil = "20";
    } else if (ACMzs < -0.85 && ACMzs >= -0.89) {
      percentil = "19";
    } else if (ACMzs < -0.89 && ACMzs >= -0.93) {
      percentil = "18";
    } else if (ACMzs < -0.93 && ACMzs >= -0.97) {
      percentil = "17";
    } else if (ACMzs < -0.97 && ACMzs >= -1.01) {
      percentil = "16";
    } else if (ACMzs < -1.01 && ACMzs >= -1.04) {
      percentil = "15";
    } else if (ACMzs < -1.04 && ACMzs >= -1.1) {
      percentil = "14";
    } else if (ACMzs < -1.1 && ACMzs >= -1.14) {
      percentil = "13";
    } else if (ACMzs < -1.14 && ACMzs >= -1.2) {
      percentil = "12";
    } else if (ACMzs < -1.2 && ACMzs >= -1.25) {
      percentil = "11";
    } else if (ACMzs < -1.25 && ACMzs >= -1.31) {
      percentil = "10";
    } else if (ACMzs < -1.31 && ACMzs >= -1.37) {
      percentil = "9";
    } else if (ACMzs < -1.37 && ACMzs >= -1.43) {
      percentil = "8";
    } else if (ACMzs < -1.43 && ACMzs >= -1.51) {
      percentil = "7";
    } else if (ACMzs < -1.51 && ACMzs >= -1.6) {
      percentil = "6";
    } else if (ACMzs < -1.6 && ACMzs >= -1.645) {
      percentil = "5";
    } else if (ACMzs < -1.645 && ACMzs >= -1.81) {
      percentil = "4";
    } else if (ACMzs < -1.81 && ACMzs >= -1.97) {
      percentil = "3";
    } else if (ACMzs < -1.97 && ACMzs >= -2.18) {
      percentil = "2";
    } else if (ACMzs < -2.18 && ACMzs >= -2.6) {
      percentil = "1";
    } else if (ACMzs < -2.6) {
      percentil = "0";
    }

    // if (ACMzs < -1.645) {
    //   resultado = "Pathological";
    // } else if (ACMzs >= -1.645) {
    //   resultado = "Normal";
    // }

    return Number(percentil);
  },

  // Calc Cerebroplacental ratio (cpr) - relacion cerebro-placentaria
  calcCPR: function (
    middleCerebralPI?: number | null,
    umbilicalArteryPI?: number | null
  ) {
    if (!middleCerebralPI || !umbilicalArteryPI) return null;
    return middleCerebralPI / umbilicalArteryPI;
  },

  //doppler_ratio_cerebroplacentario
  cerebroPlacentalRatioCentile: function (
    cpr?: number | null,
    ga?: number | null
  ) {
    const decimalWeeksGa = this.daysToWeeks(ga);
    if (!cpr || !ga || !decimalWeeksGa) return null;

    let percentil = "";

    const zs =
      (cpr -
        (-4.0636 +
          0.383 * decimalWeeksGa -
          0.0059 * (decimalWeeksGa * decimalWeeksGa))) /
      (-0.9664 +
        0.09027 * decimalWeeksGa -
        0.0014 * (decimalWeeksGa * decimalWeeksGa));
    if (zs >= 2.6) {
      percentil = "100";
    } else if (zs < 2.6 && zs >= 2.18) {
      percentil = "99";
    } else if (zs < 2.18 && zs >= 1.97) {
      percentil = "98";
    } else if (zs < 1.97 && zs >= 1.81) {
      percentil = "97";
    } else if (zs < 1.81 && zs > 1.645) {
      percentil = "96";
    } else if (zs <= 1.645 && zs >= 1.6) {
      percentil = "95";
    } else if (zs < 1.6 && zs >= 1.52) {
      percentil = "94";
    } else if (zs < 1.52 && zs >= 1.45) {
      percentil = "93";
    } else if (zs < 1.45 && zs >= 1.38) {
      percentil = "92";
    } else if (zs < 1.38 && zs >= 1.31) {
      percentil = "91";
    } else if (zs < 1.31 && zs >= 1.26) {
      percentil = "90";
    } else if (zs < 1.26 && zs >= 1.2) {
      percentil = "89";
    } else if (zs < 1.2 && zs >= 1.17) {
      percentil = "88";
    } else if (zs < 1.17 && zs >= 1.1) {
      percentil = "87";
    } else if (zs < 1.1 && zs >= 1.07) {
      percentil = "86";
    } else if (zs < 1.07 && zs >= 1.02) {
      percentil = "85";
    } else if (zs < 1.02 && zs >= 0.98) {
      percentil = "84";
    } else if (zs < 0.98 && zs >= 0.94) {
      percentil = "83";
    } else if (zs < 0.94 && zs >= 0.9) {
      percentil = "82";
    } else if (zs < 0.9 && zs >= 0.86) {
      percentil = "81";
    } else if (zs < 0.86 && zs >= 0.84) {
      percentil = "80";
    } else if (zs < 0.84 && zs >= 0.79) {
      percentil = "79";
    } else if (zs < 0.79 && zs >= 0.76) {
      percentil = "78";
    } else if (zs < 0.76 && zs >= 0.72) {
      percentil = "77";
    } else if (zs < 0.72 && zs >= 0.69) {
      percentil = "76";
    } else if (zs < 0.69 && zs >= 0.67) {
      percentil = "75";
    } else if (zs < 0.67 && zs >= 0.63) {
      percentil = "74";
    } else if (zs < 0.63 && zs >= 0.61) {
      percentil = "73";
    } else if (zs < 0.61 && zs >= 0.57) {
      percentil = "72";
    } else if (zs < 0.57 && zs >= 0.54) {
      percentil = "71";
    } else if (zs < 0.54 && zs >= 0.51) {
      percentil = "70";
    } else if (zs < 0.51 && zs >= 0.48) {
      percentil = "69";
    } else if (zs < 0.48 && zs >= 0.46) {
      percentil = "68";
    } else if (zs < 0.46 && zs >= 0.43) {
      percentil = "67";
    } else if (zs < 0.43 && zs >= 0.4) {
      percentil = "66";
    } else if (zs < 0.4 && zs >= 0.37) {
      percentil = "65";
    } else if (zs < 0.37 && zs >= 0.34) {
      percentil = "64";
    } else if (zs < 0.34 && zs >= 0.32) {
      percentil = "63";
    } else if (zs < 0.32 && zs >= 0.3) {
      percentil = "62";
    } else if (zs < 0.3 && zs >= 0.27) {
      percentil = "61";
    } else if (zs < 0.27 && zs >= 0.24) {
      percentil = "60";
    } else if (zs < 0.24 && zs >= 0.22) {
      percentil = "59";
    } else if (zs < 0.22 && zs >= 0.19) {
      percentil = "58";
    } else if (zs < 0.19 && zs >= 0.17) {
      percentil = "57";
    } else if (zs < 0.17 && zs >= 0.14) {
      percentil = "56";
    } else if (zs < 0.14 && zs >= 0.12) {
      percentil = "55";
    } else if (zs < 0.12 && zs >= 0.09) {
      percentil = "54";
    } else if (zs < 0.09 && zs >= 0.07) {
      percentil = "53";
    } else if (zs < 0.07 && zs >= 0.05) {
      percentil = "52";
    } else if (zs < 0.05 && zs >= 0.03) {
      percentil = "51";
    } else if (zs < 0.03 && zs >= -0.01) {
      percentil = "50";
    } else if (zs < -0.01 && zs >= -0.035) {
      percentil = "49";
    } else if (zs < -0.035 && zs >= -0.06) {
      percentil = "48";
    } else if (zs < -0.06 && zs >= -0.08) {
      percentil = "47";
    } else if (zs < -0.08 && zs >= -0.11) {
      percentil = "46";
    } else if (zs < -0.11 && zs >= -0.13) {
      percentil = "45";
    } else if (zs < -0.13 && zs >= -0.16) {
      percentil = "44";
    } else if (zs < -0.16 && zs >= -0.185) {
      percentil = "43";
    } else if (zs < -0.185 && zs >= -0.21) {
      percentil = "42";
    } else if (zs < -0.21 && zs >= -0.24) {
      percentil = "41";
    } else if (zs < -0.24 && zs >= -0.26) {
      percentil = "40";
    } else if (zs < -0.26 && zs >= -0.28) {
      percentil = "39";
    } else if (zs < -0.28 && zs >= -0.31) {
      percentil = "38";
    } else if (zs < -0.31 && zs >= -0.34) {
      percentil = "37";
    } else if (zs < -0.34 && zs >= -0.36) {
      percentil = "36";
    } else if (zs < -0.36 && zs >= -0.4) {
      percentil = "35";
    } else if (zs < -0.4 && zs >= -0.43) {
      percentil = "34";
    } else if (zs < -0.43 && zs >= -0.45) {
      percentil = "33";
    } else if (zs < -0.45 && zs >= -0.48) {
      percentil = "32";
    } else if (zs < -0.48 && zs >= -0.5) {
      percentil = "31";
    } else if (zs < -0.5 && zs >= -0.54) {
      percentil = "30";
    } else if (zs < -0.54 && zs >= -0.565) {
      percentil = "29";
    } else if (zs < -0.565 && zs >= -0.585) {
      percentil = "28";
    } else if (zs < -0.585 && zs >= -0.62) {
      percentil = "27";
    } else if (zs < -0.62 && zs >= -0.65) {
      percentil = "26";
    } else if (zs - 0.65 && zs >= -0.69) {
      percentil = "25";
    } else if (zs < -0.69 && zs >= -0.73) {
      percentil = "24";
    } else if (zs < -0.73 && zs >= -0.75) {
      percentil = "23";
    } else if (zs < -0.75 && zs >= -0.79) {
      percentil = "22";
    } else if (zs < -0.79 && zs >= -0.83) {
      percentil = "21";
    } else if (zs < -0.83 && zs >= -0.85) {
      percentil = "20";
    } else if (zs < -0.85 && zs >= -0.89) {
      percentil = "19";
    } else if (zs < -0.89 && zs >= -0.93) {
      percentil = "18";
    } else if (zs < -0.93 && zs >= -0.97) {
      percentil = "17";
    } else if (zs < -0.97 && zs >= -1.01) {
      percentil = "16";
    } else if (zs < -1.01 && zs >= -1.04) {
      percentil = "15";
    } else if (zs < -1.04 && zs >= -1.1) {
      percentil = "14";
    } else if (zs < -1.1 && zs >= -1.14) {
      percentil = "13";
    } else if (zs < -1.14 && zs >= -1.2) {
      percentil = "12";
    } else if (zs < -1.2 && zs >= -1.25) {
      percentil = "11";
    } else if (zs < -1.25 && zs >= -1.31) {
      percentil = "10";
    } else if (zs < -1.31 && zs >= -1.37) {
      percentil = "9";
    } else if (zs < -1.37 && zs >= -1.43) {
      percentil = "8";
    } else if (zs < -1.43 && zs >= -1.51) {
      percentil = "7";
    } else if (zs < -1.51 && zs >= -1.6) {
      percentil = "6";
    } else if (zs < -1.6 && zs >= -1.645) {
      percentil = "5";
    } else if (zs < -1.645 && zs >= -1.81) {
      percentil = "4";
    } else if (zs < -1.81 && zs >= -1.97) {
      percentil = "3";
    } else if (zs < -1.97 && zs >= -2.18) {
      percentil = "2";
    } else if (zs < -2.18 && zs >= -2.6) {
      percentil = "1";
    } else if (zs < -2.6) {
      percentil = "0";
    }

    // if (zs < -1.645) {
    //   resultado = "Pathological";
    // } else if (zs >= -1.645) {
    //   resultado = "Normal";
    // }

    return Number(percentil);
  },

  // Calc centile of dvpi (Doctus venosus PI)   doppler_ductus_venoso
  doctusVenosusPICentile: function (dvpi?: number | null, ga?: number | null) {
    let percentil = "";
    const MAX_GA = 44 * 7;
    const MIN_GA = 20 * 7;
    const decimalWeeksGa = this.daysToWeeks(ga);

    if (!dvpi || dvpi <= 0.1 || dvpi > 10) {
      return null;
    }

    if (!ga || !decimalWeeksGa || ga > MAX_GA || ga < MIN_GA) {
      return null;
    }

    const DVzs = (dvpi - (0.903 + -0.0116 * decimalWeeksGa)) / 0.1483;

    if (DVzs >= 2.6) {
      percentil = "100";
    } else if (DVzs < 2.6 && DVzs >= 2.18) {
      percentil = "99";
    } else if (DVzs < 2.18 && DVzs >= 1.97) {
      percentil = "98";
    } else if (DVzs < 1.97 && DVzs >= 1.81) {
      percentil = "97";
    } else if (DVzs < 1.81 && DVzs > 1.645) {
      percentil = "96";
    } else if (DVzs <= 1.645 && DVzs >= 1.6) {
      percentil = "95";
    } else if (DVzs < 1.6 && DVzs >= 1.52) {
      percentil = "94";
    } else if (DVzs < 1.52 && DVzs >= 1.45) {
      percentil = "93";
    } else if (DVzs < 1.45 && DVzs >= 1.38) {
      percentil = "92";
    } else if (DVzs < 1.38 && DVzs >= 1.31) {
      percentil = "91";
    } else if (DVzs < 1.31 && DVzs >= 1.26) {
      percentil = "90";
    } else if (DVzs < 1.26 && DVzs >= 1.2) {
      percentil = "89";
    } else if (DVzs < 1.2 && DVzs >= 1.17) {
      percentil = "88";
    } else if (DVzs < 1.17 && DVzs >= 1.1) {
      percentil = "87";
    } else if (DVzs < 1.1 && DVzs >= 1.07) {
      percentil = "86";
    } else if (DVzs < 1.07 && DVzs >= 1.02) {
      percentil = "85";
    } else if (DVzs < 1.02 && DVzs >= 0.98) {
      percentil = "84";
    } else if (DVzs < 0.98 && DVzs >= 0.94) {
      percentil = "83";
    } else if (DVzs < 0.94 && DVzs >= 0.9) {
      percentil = "82";
    } else if (DVzs < 0.9 && DVzs >= 0.86) {
      percentil = "81";
    } else if (DVzs < 0.86 && DVzs >= 0.84) {
      percentil = "80";
    } else if (DVzs < 0.84 && DVzs >= 0.79) {
      percentil = "79";
    } else if (DVzs < 0.79 && DVzs >= 0.76) {
      percentil = "78";
    } else if (DVzs < 0.76 && DVzs >= 0.72) {
      percentil = "77";
    } else if (DVzs < 0.72 && DVzs >= 0.69) {
      percentil = "76";
    } else if (DVzs < 0.69 && DVzs >= 0.67) {
      percentil = "75";
    } else if (DVzs < 0.67 && DVzs >= 0.63) {
      percentil = "74";
    } else if (DVzs < 0.63 && DVzs >= 0.61) {
      percentil = "73";
    } else if (DVzs < 0.61 && DVzs >= 0.57) {
      percentil = "72";
    } else if (DVzs < 0.57 && DVzs >= 0.54) {
      percentil = "71";
    } else if (DVzs < 0.54 && DVzs >= 0.51) {
      percentil = "70";
    } else if (DVzs < 0.51 && DVzs >= 0.48) {
      percentil = "69";
    } else if (DVzs < 0.48 && DVzs >= 0.46) {
      percentil = "68";
    } else if (DVzs < 0.46 && DVzs >= 0.43) {
      percentil = "67";
    } else if (DVzs < 0.43 && DVzs >= 0.4) {
      percentil = "66";
    } else if (DVzs < 0.4 && DVzs >= 0.37) {
      percentil = "65";
    } else if (DVzs < 0.37 && DVzs >= 0.34) {
      percentil = "64";
    } else if (DVzs < 0.34 && DVzs >= 0.32) {
      percentil = "63";
    } else if (DVzs < 0.32 && DVzs >= 0.3) {
      percentil = "62";
    } else if (DVzs < 0.3 && DVzs >= 0.27) {
      percentil = "61";
    } else if (DVzs < 0.27 && DVzs >= 0.24) {
      percentil = "60";
    } else if (DVzs < 0.24 && DVzs >= 0.22) {
      percentil = "59";
    } else if (DVzs < 0.22 && DVzs >= 0.19) {
      percentil = "58";
    } else if (DVzs < 0.19 && DVzs >= 0.17) {
      percentil = "57";
    } else if (DVzs < 0.17 && DVzs >= 0.14) {
      percentil = "56";
    } else if (DVzs < 0.14 && DVzs >= 0.12) {
      percentil = "55";
    } else if (DVzs < 0.12 && DVzs >= 0.09) {
      percentil = "54";
    } else if (DVzs < 0.09 && DVzs >= 0.07) {
      percentil = "53";
    } else if (DVzs < 0.07 && DVzs >= 0.05) {
      percentil = "52";
    } else if (DVzs < 0.05 && DVzs >= 0.03) {
      percentil = "51";
    } else if (DVzs < 0.03 && DVzs >= -0.01) {
      percentil = "50";
    } else if (DVzs < -0.01 && DVzs >= -0.035) {
      percentil = "49";
    } else if (DVzs < -0.035 && DVzs >= -0.06) {
      percentil = "48";
    } else if (DVzs < -0.06 && DVzs >= -0.08) {
      percentil = "47";
    } else if (DVzs < -0.08 && DVzs >= -0.11) {
      percentil = "46";
    } else if (DVzs < -0.11 && DVzs >= -0.13) {
      percentil = "45";
    } else if (DVzs < -0.13 && DVzs >= -0.16) {
      percentil = "44";
    } else if (DVzs < -0.16 && DVzs >= -0.185) {
      percentil = "43";
    } else if (DVzs < -0.185 && DVzs >= -0.21) {
      percentil = "42";
    } else if (DVzs < -0.21 && DVzs >= -0.24) {
      percentil = "41";
    } else if (DVzs < -0.24 && DVzs >= -0.26) {
      percentil = "40";
    } else if (DVzs < -0.26 && DVzs >= -0.28) {
      percentil = "39";
    } else if (DVzs < -0.28 && DVzs >= -0.31) {
      percentil = "38";
    } else if (DVzs < -0.31 && DVzs >= -0.34) {
      percentil = "37";
    } else if (DVzs < -0.34 && DVzs >= -0.36) {
      percentil = "36";
    } else if (DVzs < -0.36 && DVzs >= -0.4) {
      percentil = "35";
    } else if (DVzs < -0.4 && DVzs >= -0.43) {
      percentil = "34";
    } else if (DVzs < -0.43 && DVzs >= -0.45) {
      percentil = "33";
    } else if (DVzs < -0.45 && DVzs >= -0.48) {
      percentil = "32";
    } else if (DVzs < -0.48 && DVzs >= -0.5) {
      percentil = "31";
    } else if (DVzs < -0.5 && DVzs >= -0.54) {
      percentil = "30";
    } else if (DVzs < -0.54 && DVzs >= -0.565) {
      percentil = "29";
    } else if (DVzs < -0.565 && DVzs >= -0.585) {
      percentil = "28";
    } else if (DVzs < -0.585 && DVzs >= -0.62) {
      percentil = "27";
    } else if (DVzs < -0.62 && DVzs >= -0.65) {
      percentil = "26";
    } else if (DVzs < -0.65 && DVzs >= -0.69) {
      percentil = "25";
    } else if (DVzs < -0.69 && DVzs >= -0.73) {
      percentil = "24";
    } else if (DVzs < -0.73 && DVzs >= -0.75) {
      percentil = "23";
    } else if (DVzs < -0.75 && DVzs >= -0.79) {
      percentil = "22";
    } else if (DVzs < -0.79 && DVzs >= -0.83) {
      percentil = "21";
    } else if (DVzs < -0.83 && DVzs >= -0.85) {
      percentil = "20";
    } else if (DVzs < -0.85 && DVzs >= -0.89) {
      percentil = "19";
    } else if (DVzs < -0.89 && DVzs >= -0.93) {
      percentil = "18";
    } else if (DVzs < -0.93 && DVzs >= -0.97) {
      percentil = "17";
    } else if (DVzs < -0.97 && DVzs >= -1.01) {
      percentil = "16";
    } else if (DVzs < -1.01 && DVzs >= -1.04) {
      percentil = "15";
    } else if (DVzs < -1.04 && DVzs >= -1.1) {
      percentil = "14";
    } else if (DVzs < -1.1 && DVzs >= -1.14) {
      percentil = "13";
    } else if (DVzs < -1.14 && DVzs >= -1.2) {
      percentil = "12";
    } else if (DVzs < -1.2 && DVzs >= -1.25) {
      percentil = "11";
    } else if (DVzs < -1.25 && DVzs >= -1.31) {
      percentil = "10";
    } else if (DVzs < -1.31 && DVzs >= -1.37) {
      percentil = "9";
    } else if (DVzs < -1.37 && DVzs >= -1.43) {
      percentil = "8";
    } else if (DVzs < -1.43 && DVzs >= -1.51) {
      percentil = "7";
    } else if (DVzs < -1.51 && DVzs >= -1.6) {
      percentil = "6";
    } else if (DVzs < -1.6 && DVzs >= -1.645) {
      percentil = "5";
    } else if (DVzs < -1.645 && DVzs >= -1.81) {
      percentil = "4";
    } else if (DVzs < -1.81 && DVzs >= -1.97) {
      percentil = "3";
    } else if (DVzs < -1.97 && DVzs >= -2.18) {
      percentil = "2";
    } else if (DVzs < -2.18 && DVzs >= -2.6) {
      percentil = "1";
    } else if (DVzs < -2.6) {
      percentil = "0";
    }

    // if (DVzs > 1.645) {
    //   resultado = "Pathological";
    // } else if (DVzs <= 1.645) {
    //   resultado = "Normal";
    // }
    return Number(percentil);
  },
};
