import {
  type Study,
  type Pregnancy as DBPregnancy,
  type Patient as DBPatient,
  type Prisma,
} from "@/lib/db";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";

export type UnitNum = {
  value?: number;
  unit?: string;
};

export type NullishUnitNum = UnitNum | null | undefined;
export interface DataField {
  name: string;
  label: string;
  placeHolder: string;
  // Component: React.ComponentType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type?: string;
  props?: Record<string, unknown>;
}

export interface StudyData {
  leftUterinePulsatilityIndex?: number | null;
  rightUterinePulsatilityIndex?: number | null;
  ductusVenosusPulsatilityIndex?: number | null;
  umbilicalArteryPulsatilityIndex?: number | null;
  middleCerebralArteryPulsatilityIndex?: number | null;
  HC?: UnitNum | null;
  AC?: UnitNum | null;
  FL?: UnitNum | null;
  EFW?: UnitNum | null;
  CRL?: UnitNum | null;
  operator?: string | null;
  finishedPregnancy?: boolean | null;
  fumc?: string;
}

export type StudyWithJsonData = Omit<Study, "data"> & {
  data: StudyData;
};

export type Patient = DBPatient & {
  studies: StudyWithJsonData[];
  pregnancies: Pregnancy[];
};

export enum Specialties {
  obstetra = "Obstetra",
  ecografista = "Ecografista",
}

export interface Pregnancy extends DBPregnancy {
  prePregnancyWeight: UnitNum | null;
  newbornWeight: UnitNum | null;
  newbornGa: UnitNum | null;
}

export type PractitionerWithPrefs = Prisma.PractitionerGetPayload<{
  include: {
    preferences: true;
  };
}>;

export {
  type WeightProvider,
  type DopplerProvider,
} from "@/contexts/PractitionerPrefsProvider";

export type Refetch<T> = <TPageData>(
  options?: RefetchOptions & RefetchQueryFilters<TPageData>
) => Promise<QueryObserverResult<T | null, unknown>>;
