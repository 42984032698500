/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hello_world = () => `Hola Mundos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_equal_emu_dig = () => `Mis Pacientes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_tired_baboon_lift = () => `Inicia Sesión`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upper_lost_ray_buy = () => `Registrate Gratis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const acidic_grassy_wombat_quiz = () => `Menu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const extra_stale_panther_pinch = () => `Cerrar Sesión`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_fair_mare_play = () => `Iniciar Sesión`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_grassy_pug_dream = () => `Seguimiento dinámico del`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_deft_toad_hurl = () => `crecimiento y bienestar fetal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_dry_okapi_pick = () => `Controla de forma precisa y simple la evolución del embarazo con nuestra herramienta pensada para profesionales de la salud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_fit_mouse_soar = () => `Usar Calculadora`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mealy_patient_nils_dine = () => `Procesamiento y análisis de ultrasonografías`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_aqua_sparrow_drop = () => `ALMA permite cargar fácilmente los datos obtenidos de las ecografías de tus pacientes, realizando un análisis automatizado y preciso, determinando la edad gestacional real y evaluando la flujometría Doppler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sweet_witty_skunk_gaze = () => `Evita errores`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ok_direct_koala_enjoy = () => `Optimiza el flujo de trabajo, reduce errores manuales y accede a un informe detallado en tiempo real que facilita la toma de decisiones más rápida y efectiva`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_known_herring_fear = () => `Seguimiento de pacientes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_cuddly_pig_honor = () => `Mantén un control personalizado y dinámico de cada paciente, proporcionando un seguimiento continuo del crecimiento fetal y su bienestar a lo largo de todo el embarazo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_stock_buzzard_grow = () => `Gráficos y Predictor de peso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_close_chicken_fall = () => `Accede a gráficos que te ayudarán a identificar de inmediato si el peso fetal está dentro de los rangos normales o si varía en el tiempo. Nuestro predictor de peso fetal utiliza algoritmos avanzados para proyectar el crecimiento esperado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const left_alive_florian_aspire = () => `Simplifica el control prenatal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const keen_chunky_mammoth_pout = () => `Evita cometer errores, con ALMA podrás calcular la edad gestacional real, monitorear el peso fetal y evaluar la flujometría del Doppler obstétrico, todo a partir de los datos de las ultrasonografías`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_fancy_mammoth_sing = () => `¿La aplicación tiene algún costo?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_any_stork_zip = () => `En este momento, la aplicación es completamente gratuita. Nuestro objetivo es poner a disposición de los médicos especialistas en obstetricia una herramienta accesible que mejore el seguimiento del embarazo y el bienestar fetal. Aprovecha todas las funcionalidades sin ningún costo adicional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_polite_niklas_devour = () => `¿En qué se basan los cálculos de la aplicación?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_bold_bulldog_work = () => `Los cálculos realizados por ALMA se basan en los estándares internacionales establecidos por la Fetal Medicine Foundation (FMF) y el proyecto INTERGROWTH-21st. Estos protocolos aseguran que los resultados se ajusten a las referencias más actualizadas y precisas para el seguimiento del crecimiento fetal y la flujometría`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_patchy_crossbill_wish = () => `¿Puedo tomar decisiones clínicas basadas únicamente en la aplicación?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_vexed_jellyfish_stab = () => `No. Es importante recordar que esta herramienta está diseñada como una guía complementaria al criterio médico. Los resultados y análisis ofrecidos por la calculadora no deben ser utilizados como única base para la toma de decisiones clínicas o conductas médicas, sino como un apoyo que te ayudará a interpretar mejor los datos y acompañar el proceso de atención prenatal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_fit_platypus_ripple = () => `¿Qué tipo de pacientes puedo gestionar en la aplicación?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_strong_kudu_boost = () => `La aplicación está diseñada específicamente para pacientes embarazadas con embarazos simples (no múltiples). Podrás realizar un seguimiento detallado del embarazo, desde el primer trimestre hasta el parto, gestionando toda la información derivada de las ecografías y otros estudios relevantes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_orange_alligator_flop = () => `¿Qué información puedo ingresar en la aplicación?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const livid_even_pug_amuse = () => `Podrás cargar datos ecográficos de cada paciente, incluyendo biometría fetal, flujometría del Doppler obstétrico, y peso fetal estimado. A partir de estos datos, la aplicación generará un análisis detallado del crecimiento fetal y del bienestar general del feto`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_royal_shad_honor = () => `¿Cómo puedo visualizar el progreso de mis pacientes?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_sunny_bat_list = () => `Al crear una cuenta, la aplicación genera gráficos dinámicos y visualizaciones claras del crecimiento fetal y la flujometría del Doppler obstétrico de cada paciente, lo que te permite monitorear el desarrollo y bienestar del feto a lo largo del embarazo de manera visual y comprensible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gray_north_quail_list = () => `¿Se actualiza la aplicación con nuevos estándares o mejoras?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const acidic_ornate_pony_reap = () => `Sí, trabajamos continuamente en el desarrollo de nuevas actualizaciones que integren los últimos estándares y avances en medicina fetal. Estas actualizaciones se harán disponibles automáticamente para todos los usuarios registrados de la aplicación`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_small_dog_launch = () => `Preguntas Frecuentes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_gray_stork_cook = () => `Información`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const factual_icy_falcon_wish = () => `Términos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const antsy_loved_newt_accept = () => `Ayuda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const icy_dull_macaw_build = () => `Comunidad`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heavy_patient_insect_climb = () => `Seguinos en Twitter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lucky_mealy_clownfish_dart = () => `Contactanos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_lofty_lizard_delight = () => `Tutoriales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_seemly_deer_fond = () => `Datos que salvan vidas, decisiones que marcan la diferencia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const careful_spry_ibex_embrace = () => `Todos los derechos reservados`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_livid_tern_enrich = () => `Alma | Software Médico`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const stale_fair_wren_drip = () => `Calculadora gestacional - Realizá el seguimiento de embarazos de forma más fácil, rápida y precisa. Agiliza tu trabajo y mejora la calidad de tu atención médica`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const neat_mild_fly_jolt = () => `Ultrasonografía creada con éxito`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wise_late_salmon_buy = () => `Error creando ultrasonografía`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_cute_baboon_dart = () => `Edad Gestacional calculada a traves de Cicrunferencia Craneal. Utilizar FUMc y fecha de Ecografía para mayor precisión en los cálculos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const still_alert_jellyfish_find = () => `Por favor, indique CC del estudio`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const odd_careful_beetle_fulfill = () => `Ecografía Mayor a 14 semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loose_fluffy_niklas_persist = () => `Ecografía Menor a 14 semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_bold_fireant_intend = () => `Borrar valores`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_quiet_jaguar_surge = () => `Fecha de la Ecografía`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_misty_okapi_lift = () => `Edad ecográfica`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fair_knotty_panther_reside = () => `Menor a 14 semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_grand_mayfly_trim = () => `Mayor a 14 semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmpC = () => `FUMc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calcFromHC = () => `Calculada según Circunferencia Craneal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_flaky_walrus_advise = () => `Rango`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_lower_wombat_spark = () => `Operador`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_sad_stingray_work = () => `Guardar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HC = () => `CC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AC = () => `CA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const FL = () => `LF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EFW = () => `PEF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CRL = () => `LCR`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hc = () => `CC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ac = () => `CA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fl = () => `LF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const efw = () => `PEF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crl = () => `LCR`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grand_busy_insect_swim = () => `Biometría`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_round_deer_jolt = () => `Peso Fetal Estimado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const leftUterineArteryPulsatilityIndex = () => `IPAut Izquierda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rightUterineArteryPulsatilityIndex = () => `IPAut Derecha`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const umbilicalArteryPulsatilityIndex = () => `IP Arteria umbilical`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const middleCerebralArteryPI = () => `IP Arteria Cerebral Media`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doctusVenosusPI = () => `IP Ductus Venosus`


/**
 * @param {{ weeksNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next_any_orangutan_compose = (params) => `A partir de las ${params.weeksNumber} semanas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_still_mare_reap = () => `EG Hoy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_alive_camel_drop = () => `EG ecográfica`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EDD = () => `FPP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HCp = () => `CCp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ACp = () => `CAp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const FLp = () => `LFp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arable_weird_camel_pat = () => `pPEF FMF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_fit_slug_promise = () => `INTERGROWTH-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mealy_strong_crow_link = () => `Autp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next_quiet_tortoise_bubble = () => `AUp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const watery_less_shark_fall = () => `ACMp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_just_wolf_assure = () => `RCPp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_new_ray_foster = () => `DVp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_blue_seal_edit = () => `Calculadora Gestacional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const misty_wise_fly_ask = () => `Puedes usar la calculadora sin necesidad de registrarte, analizando así cada ultrasonografía por separado. Sin embargo, te recomendamos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_lime_robin_mend = () => `registrarte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_lazy_turtle_mend = () => `para poder dar seguimiento a tus pacientes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const true_quiet_grizzly_enjoy = () => `Alma Software médico`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loose_steep_herring_renew = () => `Calculadora`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_bald_turtle_hike = () => `Ultrasonografía eliminada con éxito`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_soft_ape_nail = () => `Error: No pudimos borrar la ultrasonografía`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_curly_cockroach_quell = () => `Fecha`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trick_stout_crow_snip = () => `Nacimiento`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_real_vulture_agree = () => `EG`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_ornate_cow_build = () => `PEF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_direct_snake_roam = () => `pPEF IG-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_elegant_llama_soar = () => `El estudio seleccionado será eliminado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cancel = () => `Cancelar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const confirm = () => `Confirmar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const strong_lucky_canary_stop = () => `Confirmar acción`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_salty_canary_attend = () => `Eliminando estudio`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const many_real_antelope_dream = () => `No tienes permismo para eliminar este estudio`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wide_slow_pelican_read = () => `Hijo/a`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tense_east_llama_pause = () => `El nombre del hijo/a ha sido actualizado con éxito`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jumpy_maroon_cowfish_adapt = () => `Actualizar nombre del hijo/a`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crazy_quaint_gorilla_forgive = () => `Nombre del hijo/a`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const weird_bald_guppy_flip = () => `El paciente ha sido eliminado de su perfil con éxito`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_spare_mink_dazzle = () => `No pudimos eliminar al paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_frail_bee_gleam = () => `Desea eliminar al paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const careful_level_marmot_imagine = () => `El paciente no será eliminado de nuestro sistema. Será desvinculado de su cuenta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lower_crazy_boar_wish = () => `Hubo un error al actualizar el embarazo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const round_jolly_cod_dream = () => `Sem`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_round_jay_link = () => `Peso al nacer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_vexed_cheetah_edit = () => `Peso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const orange_bland_chipmunk_support = () => `pINTERGROWTH-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const silly_good_dove_gleam = () => `Comencemos! Registra cada ultrasonografía de este embarazo para dar precisión y seguridad al seguimiento obstétrico`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_wild_wolf_pull = () => `PEF Hoy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dull_low_bullock_thrive = () => `Embarazo finalizado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const noisy_lazy_quail_buzz = () => `EG al nacer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_best_lionfish_read = () => `Calculado con`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_least_lobster_radiate = () => `p`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const full_round_pelican_mix = () => `Finalizar embarazo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_steep_flamingo_adapt = () => `Predictor de peso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_merry_piranha_dart = () => `Beta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_inclusive_bat_smile = () => `Puedes seleccionar la fecha para conocer el peso estimado y la EG ese día, o bien cambiar la EG o el peso para averiguar los datos restantes. Estimado por FMF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const these_lucky_meerkat_nourish = () => `Edad Gestacional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_these_husky_aspire = () => `A partir de`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const less_novel_jackal_beam = () => `Embarazo actualizado con éxito`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_silly_cat_launch = () => `Error`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bright_pretty_blackbird_renew = () => `Eliminar Paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_sleek_canary_boost = () => `Estás en área de post-término, deberías`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_wild_canary_earn = () => `finalizar el embarazo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fair_large_seahorse_transform = () => `o bien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_heavy_opossum_radiate = () => `eliminar a la paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_aware_impala_tickle = () => `Ultrasonografías`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_civil_pony_work = () => `Nueva Ultrasonografía`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_key_fish_burn = () => `Carga tu primer estudio para dar inicio al seguimiento de este nuevo embarazo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flaky_civil_nuthatch_tickle = () => `ALMA seleccionará de forma automática la mejor ultrasonografía para datar la gestación, independientemente del momento en el que la cargues`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_brief_toucan_engage = () => `Seleccione la fecha de finalización`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_dirty_marmot_hack = () => `Fecha de finalización`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_candid_skate_sing = () => `Gráfico de peso`


/**
 * @param {{ provider: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mild_sound_carp_peek = (params) => `a partir de ${params.provider}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_best_marten_feel = () => `Percentil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_sad_gadfly_pick = () => `Paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plane_day_turkey_nurture = () => `Required`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const misty_any_boar_assure = () => `Error creando el nuevo paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_orange_parakeet_propel = () => `Creado con éxito`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_less_canary_find = () => `Registrar nuevo paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_knotty_fly_peel = () => `DNI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pretty_sad_ibex_drop = () => `Nombre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mad_vivid_wasp_bask = () => `Apellido`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_yummy_swan_drop = () => `Crear`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const caring_game_marten_glow = () => `Estás en área de post-término, deberías finalizar el embarazo o bien  eliminar a la paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zany_last_ostrich_grin = () => `Ver Paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const elegant_dull_rat_heal = () => ` Aquí encontrarás el listado de tus pacientes, ordenadas por Edad Gestacional y con una vista rápida de la fecha probable de parto`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_weary_wren_learn = () => `Nuevo Paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_arable_shad_cut = () => `No hay pacientes que coincidan con la búsqueda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const neat_actual_donkey_build = () => `No tiene pacientes registrados`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_wild_hawk_grow = () => `Calendario de pacientes según FPP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const any_bard_rufian = () => `Calendario de FPP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_slick_ratin = () => `Iniciar Sesión | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ayal_barbose_ratings = () => `Iniciar Sesión en Alma Med`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_elegant_oryx_snap = () => `Hubo un error al crear tu cuenta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_watery_dove_lock = () => `Verificando email y creando su cuenta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const agent_super_falcon_swim = () => `Olvidaste pegar tu código de verificación. Hacelo y volve a intentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_basic_turkey_splash = () => `Cuenta creada con éxito`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_major_crossbill_dine = () => `Registrarse | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_crazy_buzzard_offer = () => `Registrate en Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_spicy_pony_cut = () => `Verificación de email`


/**
 * @param {{ email: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_calm_kangaroo_persist = (params) => `Enviamos un mail de verificación a ${params.email}. Porfavor copia y pega el código de verificación aquí.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_strong_snail_trim = (params) => `¡Bienvenido a Alma, ${params.name}!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tough_yummy_bumblebee_transform = () => `Para completar tu registro necesitamos que nos proporciones la siguiente información`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_grand_stork_ascend = () => `Si ya estás registrado`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const knotty_dirty_dove_love = () => `Iniciá sesión`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_keen_fly_fetch = () => `Matrícula profesional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_lazy_lionfish_bask = () => `Nacional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const novel_free_dragonfly_sail = () => `Provincial`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_ideal_larva_grasp = () => `Número de matrícula`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_weary_liger_hike = () => `Provincia`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const noisy_sleek_ox_hike = () => `Seleccione la provincia de registro de su matrícula profesional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_steep_midge_clip = () => `Especialidad`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_royal_tiger_strive = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_super_insect_twist = () => `Contraseña`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_key_barbel_succeed = () => `Estoy de acuerdo con los`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spare_short_worm_swim = () => ` términos y condiciones`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grand_fluffy_goldfish_devour = () => `¿Ya tiene cuenta?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_title = () => `Términos y Condiciones | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_header = () => `Aviso Legal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p0 = () => `Alma es una herramienta diseñada para facilitar la labor de los profesionales de la salud, específicamente en el ámbito del seguimiento de embarazo. Es crucial comprender que nuestra aplicación no está diseñada para tomar decisiones médicas por sí sola`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h1 = () => `Roles y Responsabilidades:`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p1 = () => `Alma sirve como una herramienta de apoyo para médicos, tocoginecólogos y profesionales de la salud involucrados en la atención prenatal. El usuario reconoce y acepta que la responsabilidad final de todas las decisiones médicas recae en el profesional de la salud que utiliza la aplicación`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h2 = () => `Asistencia, no Sustitución`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p2 = () => `Alma está destinado a proporcionar información y cálculos basados en datos ingresados. No reemplaza el juicio clínico, la experiencia o la evaluación directa del profesional de la salud. La aplicación no debe utilizarse como sustituto de la evaluación médica individualizada y el diagnóstico profesional`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h3 = () => `Decisiones Médicas Personalizadas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p3 = () => `La aplicación brinda datos y cálculos automatizados, pero la interpretación y aplicación de esta información deben basarse en el juicio clínico y la experiencia del profesional de la salud. Cada embarazo es único, y las decisiones deben adaptarse a la situación específica de cada paciente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h4 = () => `Responsabilidad del Usuario`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p4 = () => `El usuario reconoce que es responsable de verificar la precisión de los datos ingresados en la aplicación. Alma no asume responsabilidad por decisiones tomadas únicamente basadas en la información proporcionada por la aplicación. Al utilizar Alma, el profesional de la salud acepta y comprende plenamente que la aplicación es una herramienta de apoyo destinada a mejorar la eficiencia y la precisión en la toma de decisiones médicas, pero no reemplaza el conocimiento y el criterio del profesional de la salud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p5 = () => `Agradecemos tu comprensión y compromiso con prácticas médicas seguras y responsables`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p6 = () => `Atentamente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p7 = () => `El Equipo de Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_descriptiopn = () => `Términos y condiciones Alma Med`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_formal_gopher_aid = () => `Buscar por nombre, apellido o dni`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spry_aqua_jackdaw_pave = () => `Calculado con`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_arable_warbler_lift = () => `Fecha`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const funny_lucky_puffin_aid = () => `Peso fetal estimado (gr)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_weird_husky_slurp = () => `Soporte | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const icy_low_panther_arrive = () => `Alma | Calculadora Gestacional | Página de soporte | Cómo podemos ayudarte?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mushy_busy_angelfish_play = () => `Cómo podemos ayudarte?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const formal_fancy_gazelle_link = () => `Dejanos tu consulta y te contestaremos lo antes posible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_slow_llama_edit = () => `Enviar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_fancy_nils_edit = () => `Email inválido`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_clean_capybara_ascend = () => `No olvides hacer tu consulta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_fun_tuna_praise = () => `En breve nos pondremos en contacto`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const early_fine_ape_jest = () => `Consulta enviada`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const even_ok_ox_renew = () => `No pudimos enviar tu consulta`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmp = () => `FUM`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_many_ladybug_kick = () => `Idioma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_smug_squirrel_greet = () => `Mis pacientes | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_minor_shark_rest = () => `Nuevo Paciente | Alma`


/**
 * @param {{ patient: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_late_monkey_stop = (params) => `${params.patient} | Alma`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legal_suave_panther_sing = () => `Obstetra`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const honest_mellow_fireant_assure = () => `Ecografista`


/**
 * @param {{ config: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_fluffy_vulture_pop = (params) => `Peso calculado según la última ecografía cargada (${params.config})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const agent_still_kudu_shine = () => `Sem`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const broad_less_bee_hack = () => `pPEF`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_house_worm_bubble = () => `Cálculo de peso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_factual_hare_amaze = () => `Para realizar cálculos de peso en la app podes optar hacerlo según cálculos de FMF o Intergrowth-21st`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const steep_suave_shrike_favor = () => `Preferencias`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spare_shy_okapi_bump = () => `No puedes modificar el paciente de prueba. Registrate y crea tus pacientes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_weary_sparrow_blend = () => `Estás viendo un paciente de prueba. No podrás editarlo pero podrás ver las funcionalidades principales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmpf = () => `FUMc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_watery_jackdaw_hurl = () => `Cálculo de Doppler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_clean_yak_feast = () => `Para realizar cálculos de Doppler, puedes optar hacerlo según cálculos de FMF o Medicina Fetal Barcelona. Esta elección se mostrará por defecto cuando ingreses a tus pacientes`
