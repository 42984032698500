import { gaCentileWeights } from "./data";
interface Biometry {
  hc?: number;
  ac?: number;
  fl?: number;
}
type StrOrNum = string | number;

// utils
const log10 = (Math.log10 =
  Math.log10 ||
  function (val) {
    return Math.log(val) / Math.LN10;
  });

const centileZ = {
  1: -2.32634793163089,
  2: -2.0537489567374583,
  3: -1.8807936512593537,
  4: -1.750686113602832,
  5: -1.6448536673267895,
  6: -1.5547736317738563,
  7: -1.4757910630620987,
  8: -1.4050715973008998,
  9: -1.3407548782095653,
  10: -1.2815514341608472,
  11: -1.2265280119740998,
  12: -1.1749867019850437,
  13: -1.126391051416321,
  14: -1.0803192722620318,
  15: -1.036433328543592,
  16: -0.9944578293577838,
  17: -0.9541652060712389,
  18: -0.9153650470258659,
  19: -0.8778962597086898,
  20: -0.841621202756776,
  21: -0.8064212197649989,
  22: -0.7721931896475005,
  23: -0.738846826696429,
  24: -0.7063025419612956,
  25: -0.6744897306863396,
  26: -0.64334538717033,
  27: -0.6128129741040342,
  28: -0.5828414917417198,
  29: -0.5533847054859978,
  30: -0.5244005001441191,
  31: -0.49585033628369896,
  32: -0.46769878948608984,
  33: -0.4399131573592713,
  34: -0.41246312227627996,
  35: -0.38532046019731564,
  36: -0.35845878779208695,
  37: -0.33185334153364227,
  38: -0.3054807835814514,
  39: -0.2793190301808587,
  40: -0.2533470990313287,
  41: -0.22754497265728169,
  42: -0.20189347528322701,
  43: -0.17637416109269646,
  44: -0.15096921205605343,
  45: -0.1256613437595208,
  46: -0.10043371786742841,
  47: -0.07526986001010397,
  48: -0.050153582017460285,
  49: -0.025068907518155978,
  50: 0,
  51: 0.025068907518155978,
  52: 0.050153582017460285,
  53: 0.07526986001010397,
  54: 0.10043371786742841,
  55: 0.1256613437595208,
  56: 0.15096921205605343,
  57: 0.17637416109269619,
  58: 0.20189347528322676,
  59: 0.22754497265728138,
  60: 0.2533470990313287,
  61: 0.2793190301808587,
  62: 0.3054807835814514,
  63: 0.33185334153364227,
  64: 0.35845878779208695,
  65: 0.38532046019731564,
  66: 0.41246312227628035,
  67: 0.4399131573592717,
  68: 0.4676987894860901,
  69: 0.49585033628369896,
  70: 0.5244005001441191,
  71: 0.5533847054859978,
  72: 0.5828414917417198,
  73: 0.6128129741040342,
  74: 0.64334538717033,
  75: 0.6744897306863396,
  76: 0.7063025419612956,
  77: 0.738846826696429,
  78: 0.7721931896475005,
  79: 0.8064212197649989,
  80: 0.841621202756776,
  81: 0.8778962597086898,
  82: 0.9153650470258656,
  83: 0.9541652060712389,
  84: 0.9944578293577838,
  85: 1.036433328543592,
  86: 1.0803192722620318,
  87: 1.126391051416321,
  88: 1.1749867019850437,
  89: 1.2265280119740998,
  90: 1.2815514341608472,
  91: 1.3407548782095653,
  92: 1.4050715973008998,
  93: 1.4757910630621003,
  94: 1.5547736317738563,
  95: 1.6448536673267895,
  96: 1.750686113602832,
  97: 1.8807936512593537,
  98: 2.0537489567374583,
  99: 2.32634793163089,
};

const centileZ3 = Object.entries(centileZ).map(([key, val]) => ({
  centile: Number(key),
  z: Math.round(val * 1000),
}));

export const fmf = {
  name: "fmf",
  // FW fetal weight
  fwFromHcAcFl: function (_hc?: StrOrNum, _ac?: StrOrNum, _fl?: StrOrNum) {
    if (!_hc || !_ac || !_fl) return null;

    const hc = fmf.parseFloat(_hc) / 10;
    const ac = fmf.parseFloat(_ac) / 10;
    const fl = fmf.parseFloat(_fl) / 10;
    const validInputs = Boolean(
      hc >= 4 && hc <= 50 && ac >= 4 && ac <= 50 && fl >= 0.5 && fl <= 10
    );
    if (!validInputs) return null;

    return Math.pow(
      10,
      1.326 - 0.00326 * ac * fl + 0.0107 * hc + 0.0438 * ac + 0.158 * fl
    );
  },

  parseInt: function (val: string) {
    const ret = parseInt(val);
    return Number.isNaN(ret) ? null : ret;
  },

  parseFloat: function (val: string | number, min = 0, max = 0) {
    let ret: number | null = parseFloat(String(val));
    ret = Number.isNaN(ret) ? 0 : ret;
    if (min && ret && ret < min) {
      return 0;
    }
    if (max && ret && ret > max) {
      return 0;
    }
    return Number.isNaN(ret) ? 0 : ret;
  },

  // Returns Fetal Weight in gr
  calculateEFWFromBiometry: function ({ hc, ac, fl }: Biometry) {
    if (!hc || !ac || !fl) return null;
    const ok =
      this.parseFloat(hc) > 0 &&
      this.parseFloat(hc) < 500 &&
      this.parseFloat(ac) > 0 &&
      this.parseFloat(ac) < 500 &&
      this.parseFloat(fl) > 0 &&
      this.parseFloat(fl) < 100;
    // estimated fetal weight
    const efw = ok ? Math.round(this.fwFromHcAcFl(hc, ac, fl) ?? 0) : null;

    return efw ? Number(efw.toFixed(4)) : null;
  },

  // get Centile from Z
  zCentile: function (z: number) {
    const centile = centileZ3.find((item) => Math.round(z * 1000) <= item.z);
    return centile?.centile ?? 100;
  },

  centileZ: function (centile: number) {
    return centileZ3.find((item) => item.centile === centile);
  },

  expectedFwLog10: function (ga: number) {
    ga = ga - 199;
    const ga2 = ga * ga;
    const ga3 = ga2 * ga;
    return 3.0893 + 0.00835 * ga - 0.00002965 * ga2 - 0.00000006062 * ga3;
  },

  expectedFwSdLog10: function (ga: number) {
    return 0.02464 + 0.00005639669 * ga;
  },

  expectedFw: function (ga: number) {
    return ga >= 150 ? Math.pow(10, fmf.expectedFwLog10(ga)) : null;
  },

  // fw: peso fetal, ga: edad gestacional
  // Cálculo de Z-SCORE a partir de peso fetal y edad gestacional en DÍAS
  fwZ: function (fw: number, ga: number) {
    fw = fmf.parseFloat(fw);
    ga = fmf.parseFloat(ga);
    const expectedFwLog10 = this.expectedFwLog10(ga);
    return expectedFwLog10 > 0
      ? (log10(fw) - expectedFwLog10) / this.expectedFwSdLog10(ga)
      : null;
  },

  // Cálculo de Z-SCORE a partir de hc y edad gestacional en DÍAS
  hcZ: function (hc: number, ga: number) {
    hc = fmf.parseFloat(hc);
    ga = fmf.parseFloat(ga) / 7;
    return hc >= 40 && hc <= 500 && ga >= 12
      ? (log10(hc + 1) - 1.3369692 - 0.0596493 * ga + 0.0007494 * ga * ga) /
          0.01997
      : null;
  },
  // Cálculo de Z-SCORE a partir de ac y edad gestacional en DÍAS
  acZ: function (ac: number, ga: number) {
    ac = fmf.parseFloat(ac);
    ga = fmf.parseFloat(ga) / 7;
    return ac >= 40 && ac <= 500 && ga >= 12
      ? (log10(ac + 9) - 1.3257977 - 0.0552337 * ga + 0.0006146021 * ga * ga) /
          0.02947
      : null;
  },

  // Cálculo de Z-SCORE a partir de fl y edad gestacional en DÍAS
  flZ: function (fl: number, ga: number) {
    fl = fmf.parseFloat(fl);
    ga = fmf.parseFloat(ga) / 7;
    return fl >= 5 && fl <= 100 && ga >= 12
      ? (Math.sqrt(fl) + 1.1132444 - 0.4263429 * ga + 0.0045992 * ga * ga) /
          0.1852
      : null;
  },

  weeksToDays: (_weeks: number) => {
    return fmf.obstetricWeeksToDays(_weeks);
  },

  obstetricWeeksToDays: (_weeks: number) => {
    const [weeks, days] = String(_weeks).split(".");
    let result = 0;
    result += Number(weeks ?? 0) * 7;
    result += Number(days ?? 0);
    return result;
  },

  /**
   *  @function daysToOW
   * @description Days To Obstetric Weeks
   * @param days Días decimales o no.
   * @returns {number} Número decimal representando las semanas obstétricas {semanas.días} 12.4 (12 semanas y 4 días)
   */
  daysToOW: function (days?: number | null) {
    if (!days) return null;
    days = Number.isInteger(days) ? days : Math.round(days);
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;
    if (remainingDays) {
      return Number(`${weeks}.${remainingDays}`);
    }
    return Number(weeks);
  },

  /**
   *  @function daysToOWObject
   * @description Days To Obstetric Weeks formatted as object {weeks, days}
   * @param totalDays Días decimales o no
   * @returns {weeks, days} Numero de semanas y días
   */
  daysToOWObject: (totalDays: number | null) => {
    const data = fmf.daysToOW(totalDays);
    if (!data) return null;

    const [weeks, days] = data
      .toString()
      .split(".")
      .map((n) => (typeof n !== "string" ? 0 : Number(n)));

    return { weeks: weeks ?? 0, days: days ?? 0 };
  },

  // semanas obstetricas objeto objeto a días
  obstetricWeeksObjectToDaysNumber: (
    ga?: { weeks?: number; days?: number } | null
  ) => {
    if (!ga) return null;
    let result = 0;
    if (ga.weeks) {
      result += ga.weeks * 7;
    }
    if (ga.days) {
      result += ga.days;
    }
    return result;
  },
  // Ga objeto a semanas obstetricas numero
  obstetricWeeksObjectToObstetricWeeksNumber: (
    ga?: { weeks: number; days: number } | null
  ) => {
    if (!ga) return null;
    return `${ga?.weeks || 0}.${ga.days || 0}`;
  },

  // días decimales a semanas decimales
  daysToWeeks: (days?: number | null) => {
    if (Number.isNaN(days) || !days) return null;
    return Number(fmf.parseFloat(days / 7).toFixed(2));
  },

  // calcCentile: function (weight: number, ga: number) {
  //   const percentiles = gaCentileWeights.find((el) => el.ga === ga)?.values;
  //   if (!percentiles) return null;
  //   let lowerPercentile: number | null = null;
  //   let upperPercentile: number | null = null;
  //   for (const item of percentiles) {
  //     // const item = percentiles[i];
  //     const centile = item.centile;
  //     const centileWeight = item.value;
  //     if (
  //       centileWeight <= weight &&
  //       (lowerPercentile === null || centileWeight > lowerPercentile)
  //     ) {
  //       lowerPercentile = centile;
  //     } else if (
  //       centileWeight >= weight &&
  //       (upperPercentile === null || centileWeight < upperPercentile)
  //     ) {
  //       upperPercentile = centile;
  //     }
  //   }

  //   // Calcular el percentil interpolado
  //   if (!percentiles || !lowerPercentile || !upperPercentile) return null;

  //   const lowerWeight = percentiles?.find(
  //     (d) => d.centile === lowerPercentile
  //   )?.value;
  //   const upperWeight = percentiles.find(
  //     (d) => d.centile === upperPercentile
  //   )?.value;
  //   if (!lowerWeight || !upperWeight) return null;
  //   const weightRatio = (weight - lowerWeight) / (upperWeight - lowerWeight);

  //   const centile = Math.round(
  //     lowerPercentile + (upperPercentile - lowerPercentile) * weightRatio
  //   );
  //   return centile;
  // },
  // Obtengo Peso desde percentil + ga
  // ga en gramos
  // percentil en días.
  // calcEFW_old: function (ga?: number | null, percentil?: number | null) {
  //   if (typeof ga !== "number" || typeof percentil !== "number") return null;
  //   const percentiles = gaCentileWeights.find((el) => el.ga === ga)?.values;

  //   if (
  //     !percentiles ||
  //     percentiles.length === 0 ||
  //     !Array.isArray(percentiles)
  //   ) {
  //     return null;
  //   }

  //   if (percentil <= 3 && percentiles[0]?.value) return percentiles[0].value;
  //   if (percentil >= 97 && percentiles[percentiles?.length - 1]?.value)
  //     return percentiles[percentiles?.length - 1]!.value;

  //   // Encontrar los percentiles más cercanos
  //   let lowerPercentile: number | null = null;
  //   let upperPercentile: number | null = null;

  //   for (const { centile } of percentiles) {
  //     if (
  //       centile <= percentil &&
  //       (lowerPercentile === null || centile > lowerPercentile)
  //     ) {
  //       lowerPercentile = centile;
  //     } else if (
  //       centile >= percentil &&
  //       (upperPercentile === null || centile < upperPercentile)
  //     ) {
  //       upperPercentile = centile;
  //     }
  //   }

  //   // Calcular el peso interpolado
  //   if (lowerPercentile !== null && upperPercentile !== null) {
  //     const lowerWeight = percentiles.find(
  //       (d) => d.centile === lowerPercentile
  //     )?.value;
  //     const upperWeight = percentiles.find(
  //       (d) => d.centile === upperPercentile
  //     )?.value;
  //     const weightRatio =
  //       (percentil - lowerPercentile) / (upperPercentile - lowerPercentile);
  //     if (!lowerWeight || !upperWeight) return null;
  //     const weight = Math.round(
  //       lowerWeight + (upperWeight - lowerWeight) * weightRatio
  //     );
  //     return weight;
  //   } else {
  //     // No se encontraron percentiles cercanos, devolver null o un valor predeterminado
  //     return null;
  //   }
  // },
  /**
   * Alternative a calcEFW. Sin usar tabla. Va buscando el centile según peso dado
   */
  calcEFW: function (ga?: number | null, centile?: number | null) {
    if (
      ga === null ||
      centile === null ||
      ga === undefined ||
      centile === undefined
    )
      return null;
    let low = 250;
    let high = 5000;
    let weight = null;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const weightCentile = this.centileFromFW(mid, ga);
      if (weightCentile === null) {
        low += 1;
      } else if (weightCentile === centile) {
        weight = mid;
        break;
      } else if (weightCentile < centile) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    // console.log({ weight });
    return weight;
  },
  /**
   *
   * Cálculo percentil desde peso fetal
   */
  centileFromFW: function (fw?: number | null, ga?: number | null) {
    if (!fw || !ga) return null;
    const MIN_DAYS = 22 * 7; // 154
    const MAX_DAYS = 42 * 7;
    const ok = fw > 0 && ga >= MIN_DAYS && ga <= MAX_DAYS;
    if (ok) {
      const z = fmf.fwZ(fw, ga);

      if (!z) return null;
      const centile = fmf.zCentile(z);
      return centile;
    }
    return null;
  },
  centileFromHC: function (hc?: number | null, ga?: number | null) {
    if (!hc || !ga) return null;
    const z = fmf.hcZ(hc, ga);
    if (!z) return null;
    return fmf.zCentile(z);
  },
  centileFromAC: function (ac?: number | null, ga?: number | null) {
    if (!ac || !ga) return null;
    const z = fmf.acZ(ac, ga);
    if (!z) return null;
    return fmf.zCentile(z);
  },
  centileFromFL: function (fl?: number | null, ga?: number | null) {
    if (!fl || !ga) return null;
    const z = fmf.flZ(fl, ga);
    if (!z) return null;
    return fmf.zCentile(z);
  },

  /**
   * @function gaFromCrl Gestational Age from crown-rump length value
   * Applicable for CRL values between 30 and 84 mm, corresponding to gestational age between 9+5 to 14+1 weeks.
   * @param crl (mm) crown-rump length / longitud craneo caudal
   * @param min mm
   * @param max mm
   * @returns {gestationalAge} Float as days
   */
  gaFromCrl: function (crl: number, min?: number, max?: number) {
    const FALLBACK = null;
    min = min ?? 30;
    max = max ?? 84;

    if (!crl) return FALLBACK;
    crl = this.parseFloat(crl);
    const invalidRange = crl < min || crl > max;
    if (invalidRange) return FALLBACK;
    const result = 23.53 + 8.052 * Math.sqrt(1.037 * crl);
    return Math.round(result);
  },
  /**
   * @function gaFromHc Gestational Age from head circumference value
   * Applicable for CRL values between 30 and 84 mm, corresponding to gestational age between 9+5 to 14+1 weeks.
   * @param hc (mm) head circumference / CC
   * @param min mm
   * @param max mm
   * @returns {gestationalAge} Float as days
   */
  gaFromHc: function (hc: number, min?: number, max?: number) {
    const FALLBACK = 0;
    min = min ?? 100; // estaba en 155
    max = max ?? 280; // estaba en 226
    hc = this.parseFloat(hc, min, max);
    const result =
      hc === null || hc < min || hc > max
        ? FALLBACK
        : (7 *
            (-0.0596493 +
              Math.sqrt(
                0.0596493 * 0.0596493 -
                  4 * -0.0007494 * (1.3369692 - log10(hc + 1))
              ))) /
          (2 * -0.0007494);
    return typeof result === "number" ? Math.round(result) : result;
  },

  gaFromLmp: function (
    lmpDate: Date | string | null | undefined,
    refDate: Date = new Date()
  ) {
    if (!lmpDate) return null;
    const _date = new Date(lmpDate);
    return Math.round(fmf.diffDays(refDate, _date));
  },
  gaFromWeight: function (weight?: number | null, centile?: number | null) {
    if (weight === null || weight === undefined || !centile) return null;

    const high = 300;
    const low = 120;
    let ga = low;
    let prev = null;
    while (ga < high) {
      const weightCentile = this.centileFromFW(weight, ga);
      console.log({ weight, ga, weightCentile });
      if (weightCentile === centile) {
        break;
      }
      if (weightCentile && prev && prev > centile && weightCentile < centile) {
        ga -= 1;
        break;
      }
      prev = weightCentile;
      ga += 1;
    }

    // if (ga === high) {
    //   return low;
    // }
    return ga;
  },

  gaFromWeight_old: function (weight?: number | null, centile?: number | null) {
    if (!weight || !centile) return null;
    let closestGa: number | null = null;
    let closestCentileDiff = Infinity;
    let closestWeightDiff = Infinity;

    for (const entry of gaCentileWeights) {
      for (const val of entry.values) {
        const centileDiff = Math.abs(val.centile - centile);
        const weightDiff = Math.abs(val.value - weight);

        // Verificamos si esta combinación es la más cercana hasta ahora
        if (
          centileDiff < closestCentileDiff ||
          (centileDiff === closestCentileDiff && weightDiff < closestWeightDiff)
        ) {
          closestCentileDiff = centileDiff;
          closestWeightDiff = weightDiff;
          closestGa = entry.ga;
        }
      }
    }

    return closestGa;
  },

  diffDays: function (Adate: Date, Bdate: Date) {
    const utcThis = Date.UTC(
      Adate.getFullYear(),
      Adate.getMonth(),
      Adate.getDate(),
      0,
      0,
      0
    );
    const utcOther = Date.UTC(
      Bdate.getFullYear(),
      Bdate.getMonth(),
      Bdate.getDate(),
      0,
      0,
      0
    );
    return (utcThis - utcOther) / 86400000; //3600*24*1000
  },

  diffDaysNow: function (date: Date) {
    const now = new Date();
    return fmf.diffDays(now, date);
  },

  expectedUtpiLog10: function (ga: number) {
    // const MIN_GA = 139;Z
    const MIN_GA = 11 * 7;
    ga = fmf.parseFloat(ga);
    return ga > MIN_GA
      ? 0.625710181487661 -
          0.0053761907652857 * ga +
          0.00000886906485714508 * ga * ga
      : null;
  },
  expectedUtpiSdLog10: function (ga: number) {
    // const MIN_GA = 139;
    const MIN_GA = 11 * 7;
    ga = fmf.parseFloat(ga);
    return ga > MIN_GA ? 0.132424838 - 0.000104066 * ga : null;
  },
  MIN_GA_TO_UTERINE_ARTERY_CENTILE: 11 * 7,
  // Calculate z score from utpi
  utpiZ: function (utpi: number, ga: number) {
    // const MIN_GA = 139;
    utpi = fmf.parseFloat(utpi);
    ga = fmf.parseFloat(ga);
    const expectedUtpiLog10 = fmf.expectedUtpiLog10(ga);
    const expectedUtpiSdLog10 = fmf.expectedUtpiSdLog10(ga);
    if (!expectedUtpiLog10 || !expectedUtpiSdLog10) return null;
    return utpi > 0 && ga > fmf.MIN_GA_TO_UTERINE_ARTERY_CENTILE
      ? (Math.log10(utpi) - expectedUtpiLog10) / expectedUtpiSdLog10
      : null;
  },
  // calc z score from dvpi (Ductus venosus PI) (índice de pulsatilidad)
  dvpiZ: function (dvpi: number, ga: number) {
    dvpi = fmf.parseFloat(dvpi);
    ga = fmf.parseFloat(ga);
    const expectedDvpiSqrt = fmf.expectedDvpiSqrt(ga);
    return expectedDvpiSqrt && expectedDvpiSqrt > 0
      ? (Math.sqrt(dvpi) - expectedDvpiSqrt) / fmf.expectedDvpiSdSqrt(ga)
      : null;
  },
  MIN_GA_TO_UMBILICAL_ARTERY_CENTILE: 139,
  // Calc z score from UA (Umbilical Artery PI)
  uaZ: function (ua: number, ga: number) {
    ua = fmf.parseFloat(ua);
    ga = fmf.parseFloat(ga);
    const expectedUa = fmf.expectedUa(ga);
    const expectedUaSdLog10 = fmf.expectedUaSdLog10(ga);
    if (!expectedUa || !expectedUaSdLog10) return null;
    return ua > 0 && ga > fmf.MIN_GA_TO_UMBILICAL_ARTERY_CENTILE
      ? (log10(ua) - log10(expectedUa)) / expectedUaSdLog10
      : null;
  },
  MIN_GA_TO_MIDDLE_CEREBRAL_CENTILE: 139,
  // Calc z score from MCA (Middle cerebral PI)
  mcaZ: function (mca: number, ga: number) {
    mca = fmf.parseFloat(mca);
    ga = fmf.parseFloat(ga);
    const expectedMcaLog10 = fmf.expectedMcaLog10(ga);
    if (!expectedMcaLog10) return null;
    return mca > 0 && ga > fmf.MIN_GA_TO_MIDDLE_CEREBRAL_CENTILE
      ? (Math.log10(mca) - expectedMcaLog10) / 0.06349
      : null;
  },
  cprZ: function (cpr: number, ga: number) {
    cpr = fmf.parseFloat(cpr);
    ga = fmf.parseFloat(ga);
    const expectedCprLog10 = fmf.expectedCprLog10(ga);
    const expectedCprSdLog10 = fmf.expectedCprSdLog10(ga);

    if (!expectedCprLog10 || !expectedCprSdLog10) return null;
    return cpr > 0 && ga > 139
      ? (Math.log10(cpr) - expectedCprLog10) / expectedCprSdLog10
      : null;
  },
  expectedMcaLog10: function (ga: number) {
    ga = fmf.parseFloat(ga);
    // return ga > 139 ? 0.314490585289766 - 0.00709951527485184 * ga + 0.0000634517903653943 * ga * ga - 0.000000144266833031095 * ga * ga * ga : null;
    return ga > 139
      ? 0.3117131 -
          0.007099515 * ga +
          0.00006345179 * ga * ga -
          0.0000001442668 * ga * ga * ga
      : null; //2018-09-25
  },
  expectedCprSdLog10: function (ga: number) {
    ga = fmf.parseFloat(ga);
    // return ga > 139 ? 0.194840477027757 - 0.00122032708182306 * ga + 0.00000326212994715347 * ga * ga : null;
    return ga > 139
      ? 0.1948405 - 0.001220327 * ga + 0.00000326213 * ga * ga
      : null;
  },
  expectedUa: function (ga: number) {
    ga = fmf.parseFloat(ga);
    return ga > 139 ? 1.64737123 - 0.003004566 * ga : null;
  },
  expectedCprLog10: function (ga: number) {
    ga = fmf.parseFloat(ga);
    // return ga > 139 ? -0.35465048534466 + 0.000396929607921305 * ga + 0.0000319939833649952 * ga * ga - 0.0000000926574868728764 * ga * ga * ga : null;
    return ga > 139
      ? -0.3564455 +
          0.0003969296 * ga +
          0.00003199398 * ga * ga -
          0.00000009265749 * ga * ga * ga
      : null; //2018-09-25
  },

  expectedUaSdLog10: function (ga: number) {
    ga = fmf.parseFloat(ga);
    return ga > 139
      ? 0.0871258999174847 -
          0.000293587139447361 * ga +
          0.000000935493584242832 * ga * ga
      : null;
  },

  expectedDvpiSqrt: function (ga: number) {
    ga = ga / 7;
    return ga >= 18 && ga <= 42 ? 0.0002 * ga * ga - 0.019 * ga + 1.0847 : null;
  },

  expectedDvpiSdSqrt: function (ga: number) {
    ga = ga / 7;
    return 0.00005 * ga + 0.0912;
  },
  // DOPPLER MATHS
  // Calculate centile from Uterine Artery PI (índice de pulsatilidad)
  uterineArteryPICentile: function (utpi?: number | null, ga?: number | null) {
    if (!utpi || !ga) return null;
    const z = fmf.utpiZ(utpi, ga);
    if (!z) return null;
    return fmf.zCentile(z);
  },
  // Calc centile of dvpi (Doctus venosus PI)
  doctusVenosusPICentile: function (dvpi?: number | null, ga?: number | null) {
    if (!dvpi || !ga) return null;
    const z = fmf.dvpiZ(dvpi, ga);
    if (!z) return null;
    return fmf.zCentile(z);
  },
  // Calc centile from UA (Umbilical Artery PI)
  umbilicalArteryPICentile: function (ua?: number | null, ga?: number | null) {
    if (!ua || !ga) return null;
    const z = fmf.uaZ(ua, ga);
    if (!z) return null;
    return fmf.zCentile(z);
  },
  // Calc centile from MCA (Middle cerebral PI)
  middleCerebralPICentile: function (mca?: number | null, ga?: number | null) {
    if (!mca || !ga) return null;
    const z = fmf.mcaZ(mca, ga);
    if (!z) return null;
    return fmf.zCentile(z);
  },
  // Calc Cerebroplacental ratio (cpr) - relacion cerebro-placentaria
  calcCPR: function (
    middleCerebralPI?: number | null,
    umbilicalArteryPI?: number | null
  ) {
    if (!middleCerebralPI || !umbilicalArteryPI) return null;
    return middleCerebralPI / umbilicalArteryPI;
  },

  cerebroPlacentalRatioCentile: function (
    cpr?: number | null,
    ga?: number | null
  ) {
    if (!cpr || !ga) return null;
    const z = fmf.cprZ(cpr, ga);
    if (!z) return null;
    return fmf.zCentile(z);
  },
};

// console.log("gaFromHc", fmf.daysToOW(fmf.gaFromHc(100)));
// console.log(
//   "1) Centil arteria uterina :: uterineArteryPICentile(utpi=0.85, ga=30*7)",
//   fmf.uterineArteryPICentile(0.85, 30 * 7)
// );

// console.log(
//   "2) Centil ductus venoso :: doctusVenosusPICentile(dvpi=0.5, ga=30*7)",
//   fmf.doctusVenosusPICentile(0.5, 30 * 7)
// );
// console.log(
//   "3) Centil arteria umbilical :: umbilicalArteryPICentile(ua=1, ga=30*7)",
//   fmf.umbilicalArteryPICentile(1, 30 * 7)
// );

// console.log(
//   "4) Centil de la ip cerebral media :: middleCerebralPICentile(mca=2, ga=30*7)",
//   fmf.middleCerebralPICentile(2, 30 * 7)
// );
// console.log(
//   "5) Relacion cerebro-placentaria :: calcCPR(mca=2, ua=1)",
//   fmf.calcCPR(2, 1)
// );
// console.log(
//   `6) Percentil rel cerebro-placentaria :: cerebroPlacentalRatioCentile(cpr=2, ga=30*7)`,
//   fmf.cerebroPlacentalRatioCentile(2, 30 * 7)
// );
// const fetalWeightFromBiometry = fmf.calculateEFWFromBiometry({
//   hc: 210,
//   ac: 278,
//   fl: 65,
// });
// console.log(
//   `[calculateEFWFromBiometry function]: hc=200  ac=150 fl=61 | Fetal Weight: ${fetalWeightFromBiometry}gr`
// );

// const calculatePercentileResult = fmf.centileFromFW(460, fmf.weeksToDays(22));
// console.log(
//   `[FMF centileFromFW function]: fetalWeight=460  gestationalAge=22 | Percentile: ${calculatePercentileResult}`
// );

// const crl = 32;
// const gestationalAgeFromCRL = fmf.daysToOWObject(
//   fmf.gaFromCrl(crl)
// );
// console.log(
//   `[gaFromCrl function]: crl=${crl} | Gestational Age: weeks: ${gestationalAgeFromCRL?.weeks}, days: ${gestationalAgeFromCRL?.days}`
// );

// const hc = 152;
// const gestationalAgeFromHC = fmf.daysToOWObject(
//  fmf.gaFromHc(hc)
// );
// console.log(
//   `[gaFromHc function]: hc=${hc} | Gestational Age: weeks: ${gestationalAgeFromHC.weeks}, days: ${gestationalAgeFromHC.days}`
// );

// const lastMenstrualPeriod = "09-27-23";
// const result3 = fmf.daysToOWObject(
//   fmf.gaFromLmp(lastMenstrualPeriod)
// );
// console.log(
//   `[gaFromLmp function]: lmp=${lastMenstrualPeriod} | Gestational Age: weeks: ${result3.weeks}, days: ${result3.days}`
// );

// console.log("fmf.daysToWeeks", fmf.daysToOWObject(119));
// const gestationalAge = 21 * 7;

// console.log(
//   `[centileFromHC function]: hc=118 ga=${gestationalAge} | Centile: ${fmf.centileFromHC(
//     180,
//     gestationalAge
//   )}`
// );
// console.log(
//   `[centileFromAC function]: ac=98 ga=${gestationalAge} | Centile: ${fmf.centileFromAC(
//     98,
//     gestationalAge
//   )}`
// );
// console.log(
//   `[centileFromFL function]: fl=18.8 ga=${gestationalAge} | Centile: ${fmf.centileFromFL(
//     18.8,
//     gestationalAge
//   )}`
// );

// //calcCentile
// const GA = 256;
// const weight = 2715;
// const percentil = calcCentile(weight, GA);
// console.log(`Para GA=${GA} y weight=${weight}, el percentil es: ${percentil}`);

// [
// {
//   edadGestacionalDias: 36 * 7 + 4,
//   percentil: 28,
//   pesoFetal: 2714.92000001284,
// },
// { edadGestacionalDias: 24 * 7, percentil: 23, pesoFetal: 600 },
// { edadGestacionalDias: 24 * 7 + 1, percentil: 15, pesoFetal: 600 },
// { edadGestacionalDias: 24 * 7 + 2, percentil: 10, pesoFetal: 600 },
// { edadGestacionalDias: 25 * 7 + 2, percentil: 32, pesoFetal: 750 },
// { edadGestacionalDias: 153, percentil: 50, pesoFetal: null },
// ].forEach((d) => {
//   const peso = fmf.calcEFW(d.edadGestacionalDias, d.percentil);
//   console.log(
//     `Para GA=${d.edadGestacionalDias} y percentil=${d.percentil}, el peso debería ser ${d.pesoFetal} y es::: ${peso}`
//   );
// });

// console.log(fmf.gaFromWeight(1850, 0));
// console.log(fmf.gaFromWeight_old(1850, 0));

// // Script para medir el rendimiento de las funciones
// function evaluatePerformance(
//   weight: number,
//   centile: number,
//   iterations: number = 1000
// ) {
//   // Evaluación de gaFromWeight
//   console.time("gaFromWeight");
//   for (let i = 0; i < iterations; i++) {
//     fmf.gaFromWeight(weight, centile);
//   }
//   console.timeEnd("gaFromWeight");

//   // Evaluación de gaFromWeight2
//   console.time("gaFromWeight2");
//   for (let i = 0; i < iterations; i++) {
//     fmf.gaFromWeight2(weight, centile);
//   }
//   console.timeEnd("gaFromWeight2");
// }

// // Ejemplo de uso
// evaluatePerformance(1850, 3);
