export const intergrowth = {
  name: "intergrouth",
  // días decimales a semanas decimales
  daysToWeeks: (days?: number | null) => {
    if (Number.isNaN(days) || !days) return null;
    return Number(Number(days / 7).toFixed(2));
  },
  /**
   *
   * @param ga as decimal weeks
   */
  expectedMean(ga: number) {
    // const decimalGa = fmf.daysToWeeks(ga);
    // if (!decimalGa) return null;
    return (
      4.956737 +
      0.0005019687 * Math.pow(ga, 3) -
      0.0001227065 * Math.pow(ga, 3) * Math.log(ga)
    );
  },

  /**
   *
   * @param ga as decimal weeks
   */

  cv(ga: number) {
    return (
      0.0001 *
      (-6.997171 +
        0.057559 * Math.pow(ga, 3) -
        0.01493946 * Math.pow(ga, 3) * Math.log(ga))
    );
  },
  /**
   *
   * @param ga as decimal weeks
   */
  skewness(ga: number) {
    return (
      -4.257629 - 2162.234 * Math.pow(ga, -2) + 0.0002301829 * Math.pow(ga, 3)
    );
  },
  normalcdf(X: number) {
    //HASTINGS.  MAX ERROR = .000001
    const T = 1 / (1 + 0.2316419 * Math.abs(X));
    const D = 0.3989423 * Math.exp((-X * X) / 2);
    let prob =
      D *
      T *
      (0.3193815 +
        T * (-0.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))));
    if (X > 0) {
      prob = 1 - prob;
    }
    return prob;
  },
  computeNormalDistribution(x: number, mean = 0, standardDerivation = 1) {
    let prob;

    if (standardDerivation < 0) {
      //   console.log("The standard deviation must be nonnegative.");
    } else if (standardDerivation == 0) {
      if (x < mean) {
        prob = 0;
      } else {
        prob = 1;
      }
    } else {
      prob = this.normalcdf((x - mean) / standardDerivation);
      prob = Math.round(100000 * prob) / 100000;
    }

    return prob;
  },

  getZscore(ga: number, efw: number) {
    const decimalGa = this.daysToWeeks(ga);
    if (!decimalGa) return null;
    const skewness = this.skewness(decimalGa);
    const logEfw = Math.log(efw); // J4
    const cv = this.cv(decimalGa); // H4
    const expectedMean = this.expectedMean(decimalGa); // G4
    if (skewness === 0) {
      return Math.pow(cv, -1) * Math.log(logEfw / expectedMean);
    } else {
      return (
        Math.pow(cv * skewness, -1) *
        (-1 + Math.pow(logEfw / expectedMean, skewness))
      );
    }
  },

  getCentile(zScore: number) {
    const dist = this.computeNormalDistribution(zScore);
    if (!dist) return null;
    return dist * 100;
  },
  ////------> main
  centileFromFW: function (fw?: number | null, ga?: number | null) {
    if (!fw || !ga) return null;

    const z = intergrowth.getZscore(ga, fw);
    if (!z) return null;
    const centile = intergrowth.getCentile(z);
    if (!centile) return null;
    return parseInt(String(centile));
  },

  // Obtengo Peso desde percentil + ga
  // ga en gramos
  // centile en días.
  calcEFW: function (ga?: number | null, centile?: number | null) {
    if (typeof ga !== "number" || typeof centile !== "number") return null;
    let low = 250;
    let high = 5000;
    let weight = null;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const weightCentile = intergrowth.centileFromFW(mid, ga);

      if (weightCentile === null) {
        low += 1;
      } else if (weightCentile === centile) {
        weight = mid;
        break;
      } else if (weightCentile < centile) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    // console.log({ weight });
    return weight;
  },
};

// console.log("IG TEST", intergrowth.calcEFW(275, 5));

// console.log("INTERGROWTH_EFW_calculator");
// const ga = 27 * 7;
// const efw = 1100;

// const z = intergrowth.getZscore(ga, efw);
// const centile = intergrowth.getCentile(z!);
// console.log(
//   `Edad Gestacional: ${
//     ga / 7
//   }, Peso: ${efw} || zScore: ${z}, Percentil: ${centile}`
// );

// const ga2 = 22 * 7 + 3;
// const efw2 = 550;

// const z2 = intergrowth.getZscore(ga2, efw2);
// const centile2 = intergrowth.getCentile(z2!);
// console.log(
//   `Edad Gestacional: ${
//     ga2 / 7
//   }, Peso: ${efw2} || zScore: ${z2}, Percentil: ${centile2}`
// );
