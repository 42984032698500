import React, { createContext, useContext, useMemo, useState } from "react";
import { usePractitioner } from "@/utils/client/queries/usePractitioner";
import { WeightCalculatorOptions, type Preferences } from "@prisma/client";
import usePractitionerId from "@/hooks/usePractitionerId";

import { intergrowth, fmf, barcelona } from "@/lib/core";
import { type PractitionerByIdResponse } from "@/pages/api/practitioners/[id]";
import { type Refetch } from "@/types";

function getWeightProvider(pref: WeightCalculatorOptions) {
  switch (pref) {
    case WeightCalculatorOptions.fmf:
      return fmf;
    case WeightCalculatorOptions.intergrouth:
      return intergrowth;
    case WeightCalculatorOptions.barcelona:
      return barcelona;
    default:
      return fmf;
  }
}

function getDopplerProvider(pref: WeightCalculatorOptions) {
  switch (pref) {
    case WeightCalculatorOptions.fmf:
      return fmf;
    case WeightCalculatorOptions.barcelona:
      return barcelona;
    default:
      return fmf;
  }
}

export type WeightProvider = ReturnType<typeof getWeightProvider>;
export type DopplerProvider = ReturnType<typeof getDopplerProvider>;

type PractitionerPrefsContextValue = {
  isLoading: boolean;
  preferences?: Preferences | null;
  weightPref: WeightCalculatorOptions;
  weightProvider: WeightProvider;
  weightIsFmf: boolean;
  weightIsIntergrouth: boolean;
  refetch: Refetch<PractitionerByIdResponse>;
  setWeightPref: (pref: WeightCalculatorOptions) => void;
  resetWeightPref: () => void;
  dopplerProvider: DopplerProvider;
  setDopplerPref: (pref: WeightCalculatorOptions) => void;
};

const fallback = {
  isLoading: true,
  preferences: null,
  weightPref: WeightCalculatorOptions.fmf,
  weightIsFmf: true,
  weightIsIntergrouth: false,
  weightProvider: fmf,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetch: (async () => ({})) as Refetch<PractitionerByIdResponse>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setWeightPref: (pref: WeightCalculatorOptions) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  resetWeightPref: () => {},
  // Doppler ---->>
  dopplerProvider: fmf,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setDopplerPref: (pref: WeightCalculatorOptions) => {},
};
export const PractitionerPrefsContext =
  createContext<PractitionerPrefsContextValue>(fallback);

export const PractitionerPrefsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const practitionerId = usePractitionerId();
  const { data, isLoading, refetch } = usePractitioner({ practitionerId });

  // Estado para sobrescribir manualmente el provider
  const [manualWeightPref, setManualWeightPref] =
    useState<WeightCalculatorOptions | null>(null);

  const [manualDopplerPref, setManualDopplerPref] =
    useState<WeightCalculatorOptions | null>(null);

  // Determina el `weightPref` a usar (manual o basado en datos)
  const weightPref = useMemo(
    () =>
      manualWeightPref ??
      data?.practitioner?.preferences?.weightCalculator ??
      WeightCalculatorOptions.fmf,
    [manualWeightPref, data]
  );

  const dopplerPref = useMemo(
    () =>
      manualDopplerPref ??
      // data?.practitioner?.preferences?.weightCalculator ??
      WeightCalculatorOptions.fmf,
    [manualDopplerPref]
  );

  const weightProvider = useMemo(
    () => getWeightProvider(weightPref),
    [weightPref]
  );

  const dopplerProvider = useMemo(
    () => getDopplerProvider(dopplerPref),
    [dopplerPref]
  );

  const value = useMemo(
    () => ({
      isLoading,
      preferences: data?.practitioner?.preferences,
      weightPref,
      weightIsFmf: weightPref === WeightCalculatorOptions.fmf,
      weightIsIntergrouth: weightPref === WeightCalculatorOptions.intergrouth,
      weightProvider,
      refetch,
      setWeightPref: setManualWeightPref, // Permite sobrescribir manualmente
      resetWeightPref: () => setManualWeightPref(null), // Restablece al valor predeterminado
      dopplerProvider,
      setDopplerPref: setManualDopplerPref,
    }),
    [isLoading, data, weightPref, weightProvider, refetch, dopplerProvider]
  );

  return (
    <PractitionerPrefsContext.Provider value={value}>
      {children}
    </PractitionerPrefsContext.Provider>
  );
};
